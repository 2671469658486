import fileSize from 'filesize'
import { mixed } from 'yup'

export const fileSizeLimitTest = (limitBytes: number) => {
  return mixed().test(
    'fileSize',
    `under ${fileSize(limitBytes, { spacer: '' })}`,
    value => !value || value.size <= limitBytes,
  )
}
