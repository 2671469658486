import React, { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import UserContext from '../../../contexts/UserContext'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const user = useContext(UserContext)

  const renderAuthComponent = (Component: typeof children) => () => {
    if (!Component) {
      return null
    }

    return user ? (
      Component
    ) : (
      <Redirect
        to={{
          pathname: '/sign-in',
        }}
      />
    )
  }

  return <Route {...rest} render={renderAuthComponent(children)} />
}

export default PrivateRoute
