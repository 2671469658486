import firebase from 'firebase/app'
import 'firebase/firestore'
import { DEFAULT_AVATARS } from '../constants/moss'
import { ICanvas } from '../firebase'
import { IParticipant } from '../types/participant'
import { ITeam } from '../types/teams'

export const transformCanvasDocData = (
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
): ICanvas => {
  return {
    id: doc.id,
    createdAt: doc.data().createdAt,
    shortCode: doc.data().shortCode,
    project: doc.data().project,
    name: doc.data().name || 'No name', // currently some canvases have no name in firebase
  }
}

export const transformUserDocDataToParticipant = (
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
  idx: number,
): IParticipant => {
  return {
    id: doc.id,
    lastSeenAt: doc.data().lastSeenAt, // TODO: what happens if lastSeenAt is null?
    displayName: doc.data().displayName,
    avatarSrc: DEFAULT_AVATARS[idx % DEFAULT_AVATARS.length],
  }
}

export const transformTeamData = (doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): ITeam => {
  return {
    id: doc.id,
    name: doc.data()?.name,
    logoUrl: doc.data()?.logoUrl,
    membershipCount: doc.data()?.membershipCount,
  }
}
