import firebase from 'firebase/app'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import UserContext from '../../../contexts/UserContext'
import { updateUserName } from '../../../logic/firebase'
import { Button, Card, H2, Input, SmallText } from '../../atoms'

interface IProps {
  onCancelClick: () => void
}

const validationSchema = object({
  name: string().required('Required'),
})

const logoutHandler = () => {
  firebase
    .auth()
    .signOut()
    .catch(function (error) {
      // TODO: figure out proper alert
      toast('An error occurred while trying to logout.')
    })
}

const AccountSettings = ({ onCancelClick }: IProps) => {
  const user = useContext(UserContext)

  if (!user)
    return (
      <Card>
        <Card.Body padding="form">
          <H2>...Error getting user information</H2>
        </Card.Body>
      </Card>
    )

  return (
    <Formik
      initialValues={{ name: user.displayName || '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateUserName(user, values.name)

          toast.success('Account updated!')
        } catch (err) {
          console.log(err)
          toast.error('Something went wrong with updating your account')
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, handleBlur, handleChange, values }) => (
        <div>
          <Form id="account-settings">
            <Card.Body className="settings-content--inner" padding="form" size="xl">
              <SmallText>Account</SmallText>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Enter your name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth={true}
                validation={true}
              />

              <Input fullWidth={true} value={user.email || ''} disabled={true} />

              <Button fullWidth={true} size="medium" variant="gray-1" type="button" onClick={logoutHandler}>
                Log out
              </Button>
            </Card.Body>
          </Form>

          <div className="settings-update">
            <Button size="medium" variant="blue" type="submit" form="account-settings" disabled={isSubmitting}>
              {isSubmitting ? 'Updating' : 'Update'}
            </Button>

            <Button size="medium" variant="gray-1" type="button" onClick={onCancelClick}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default AccountSettings
