import firebase from 'firebase/app'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import { FIREBASE_EMAIL_LOCAL_STORAGE_KEY } from '../../constants/localStorage'
import UserContext from '../../contexts/UserContext'
import { FIREBASE_ACTION_CODE_SETTINGS, firestore } from '../../firebase'
import { Button, Input, Paragraph, TextLink, UnstyledButton } from '../../ui/atoms'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'
import './SignIn.scss'

const SignIn = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const history = useHistory()
  const user = useContext(UserContext)

  useEffect(() => {
    if (!user) {
      return
    }

    firestore
      .collection('users')
      .doc(user.uid)
      .get()
      .then(userDoc => {
        const userData = userDoc.data()

        if (!userData) {
          return
        }

        if (userData.currentCanvasId) {
          history.push(`/canvas/${userData.currentCanvasId}`)
          return
        }

        if (userData.onboardingInstallSyncerDone) {
          history.push('/')
          return
        }

        history.push('/onboarding-name')
      })
  }, [user, history])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: object({
      email: string().required('Required'),
    }),
    onSubmit: async values => {
      setLoading(true)

      // TODO: remove this initial check once sign up is not restricted
      try {
        const results = await firebase.functions().httpsCallable('userWithEmailExists')({ email: values.email })

        if (!results.data.success) {
          toast.error('Please request an invite to try Moss')
          setLoading(false)
          return
        }
      } catch (err) {
        console.log(err)
        toast.error('Something went wrong')
        setLoading(false)
        return
      }

      try {
        await firebase.auth().sendSignInLinkToEmail(values.email, FIREBASE_ACTION_CODE_SETTINGS)

        window.localStorage.setItem(FIREBASE_EMAIL_LOCAL_STORAGE_KEY, values.email)
        setSuccess(true)
      } catch (err) {
        console.log(err)
        toast.error('Something went wrong')
      }

      setLoading(false)
    },
  })

  if (success) {
    return (
      <OnboardingLayout
        mainHeading="We just emailed you."
        subHeading="Follow the link we sent to log into Moss."
        footer={
          <Paragraph>
            Don't see the email?{' '}
            <UnstyledButton onClick={() => setSuccess(false)}>
              <TextLink>Try again</TextLink>
            </UnstyledButton>
            {'.'}
          </Paragraph>
        }
      ></OnboardingLayout>
    )
  }

  return (
    <OnboardingLayout
      mainHeading="Welcome back to Moss"
      footer={
        <Paragraph>
          Don't have an account?{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://mossworkspace.com/">
            <TextLink>Request Alpha access</TextLink>
          </a>
          {'.'}
        </Paragraph>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="onboarding-layout-hero">
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            onChange={formik.handleChange}
            value={formik.values.email}
            fullWidth={true}
            large={true}
          />

          <Button type="submit" variant="blue" disabled={loading} fullWidth={true}>
            {loading ? 'Logging in' : 'Log in'}
          </Button>
        </div>
      </form>
    </OnboardingLayout>
  )
}

export default SignIn
