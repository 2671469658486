import './SidebarSectionHeader.scss'

import React from 'react'

import AddIcon from '../../../assets/icons/action-add_darkGray1.svg'
import { Button, Icon, ListLabel } from '../../atoms'

interface IProps {
  title: string
  onAddClick: () => void
}

const SidebarSectionHeader = ({ title, onAddClick }: IProps) => {
  return (
    <ListLabel className="sidebar-section-header">
      <span>{title}</span>

      <Button
        className="sidebar-add-button"
        onClick={onAddClick}
        size="tiny"
        variant="white"
        borderless={true}
        tooltip={title === 'Projects' ? 'Create a new project' : 'Create a new workspace'}
      >
        <Icon src={AddIcon} alt="Add Icon" />
      </Button>
    </ListLabel>
  )
}

export default SidebarSectionHeader
