import React, { createContext, useState } from 'react'

import { ITeam } from '../types/teams'

interface IProps {
  children?: any
}

type ContextType = {
  currentTeam: ITeam | null
  setCurrentTeam: React.Dispatch<React.SetStateAction<ITeam | null>>
}
const CurrentTeamContext = createContext<ContextType>({
  currentTeam: null,
  setCurrentTeam: () => {},
})

const CurrentTeamProvider = ({ children }: IProps) => {
  const [currentTeam, setCurrentTeam] = useState<ITeam | null>(null)

  return <CurrentTeamContext.Provider value={{ currentTeam, setCurrentTeam }}>{children}</CurrentTeamContext.Provider>
}

export default CurrentTeamContext

export { CurrentTeamProvider }
