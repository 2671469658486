import './ListLabel.scss'

import React from 'react'

interface IProps {
  children: any
  className?: string
}

export const ListLabel = ({ children, className }: IProps) => {
  return <div className={`list-label ${className ? className : ''}`}>{children}</div>
}

export default ListLabel
