import React from 'react'

interface IProps {
  src: string
  alt?: string
  className?: string
}

const Icon = ({ src, alt, className }: IProps) => (
  <img className={`icon ${className ? className : ''}`} src={src} alt={alt ? alt : ''} width="12" height="12" />
)

export default Icon
