import './Paragraph.scss'

import React from 'react'

interface IProps {
  children: any
  bold?: boolean
  className?: string
}

const Paragraph = ({ children, bold, className }: IProps) => (
  <p className={`paragraph ${bold ? 'paragraph--bold' : ''} ${className || ''}`}>{children}</p>
)

export default Paragraph
