import './AddSection.scss'

import React, { useEffect, useRef, useState } from 'react'

import AddIcon from '../../../assets/icons/action-add_darkGray2.svg'
import { AddMenu } from '../'
import { KonvaElement } from '../../../types/konva'
import { Button, Icon } from '../../atoms'

interface IProps {
  canvasId: string
  stageRef: React.RefObject<KonvaElement>
  zoom: number
  onFileDrop: (fileList: FileList | null, event: any) => void
  setDraftNote: React.Dispatch<React.SetStateAction<{ x: number; y: number } | null>>
}

const AddSection = ({ canvasId, stageRef, zoom, onFileDrop, setDraftNote }: IProps) => {
  const [isMenuOpen, setOpenMenu] = useState(false)
  const menuRef = useRef(null)
  var target = Object(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      target = event.target
      if (menuRef && menuRef.current) {
        if (!(menuRef.current! as any).contains(target)) {
          // slightly sloppy way to do this
          // forces you to manually assign anything that shouldn't close the menu
          if (!target.className.includes('dont-close-add-menu')) {
            setOpenMenu(false)
          }
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (
    <div ref={menuRef}>
      <div className="add-button">
        <Button
          variant="gray-1"
          size="small"
          onClick={() => setOpenMenu(!isMenuOpen)}
          tooltip={isMenuOpen ? '' : 'Populate your workspace'}
          tooltipPos={'bottom'}
        >
          <Icon src={AddIcon} alt="Add Icon" />
          <span>Add</span>
        </Button>
      </div>

      {isMenuOpen ? (
        <div className="add__container">
          <AddMenu
            canvasId={canvasId}
            stageRef={stageRef}
            zoom={zoom}
            onFileDrop={onFileDrop}
            setDraftNote={setDraftNote}
            setOpenMenu={setOpenMenu}
          />
        </div>
      ) : null}
    </div>
  )
}

export default AddSection
