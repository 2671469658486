import './OnboardingInviteTeam.scss'

import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import CloseIcon from '../../assets/icons/action-close_darkGray2.svg'
import { ONBOARDING_TEAM_LOCAL_STORAGE_KEY } from '../../constants/localStorage'
import UserContext from '../../contexts/UserContext'
import { inviteUsersToTeam } from '../../logic/firebase'
import { Button, Divider, Emoji, H3, Icon, Input, ListItem, ListLabel, Paragraph, TextLink } from '../../ui/atoms'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'

const OnboardingInviteTeam = () => {
  const [inviteEmails, setInviteEmails] = useState<string[]>([])
  const [teamId] = useState(localStorage.getItem(ONBOARDING_TEAM_LOCAL_STORAGE_KEY))
  const [sendingInvites, setSendingInvites] = useState(false)
  const user = useContext(UserContext)
  const history = useHistory()

  const validationSchema = object({
    email: string().email('Invalid email address').notOneOf(inviteEmails, 'Email already added').required('Required'),
  })

  const removeEmail = (emailToRemove: string) => {
    setInviteEmails(prevInviteEmails => prevInviteEmails.filter(email => email !== emailToRemove))
  }

  const sendInvitesHandler = () => {
    setSendingInvites(true)

    if (!teamId) {
      toast.error('Something went wrong')
      console.log('No teamId available')
      return
    }

    if (!user) {
      toast.error('Something went wrong')
      console.log('No user available')
      return
    }

    inviteUsersToTeam(teamId, inviteEmails)
      .then(() => {
        history.push('/install')
      })
      .catch(err => {
        console.log(err)
        toast.error('Something went wrong')
        setSendingInvites(false)
      })
  }

  useEffect(() => {
    if (!teamId) {
      // TODO: if team id not in local storage, figure out flow
      // if (!user) {
      //   return
      // }
      // firestore.collection('users').doc(user.uid).get()
    }
  }, [teamId])

  return (
    <OnboardingLayout
      mainHeading="Invite your team"
      subHeading={`Enter your teams’ emails so they can join your workspace.
      You can also do this in the app.`}
      footer={
        <Paragraph>
          Not sure who to invite?{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/Sharing-permissions-dc3da71039ba430f8691f8b472d114a6"
          >
            <TextLink>Learn about sharing & permissions</TextLink>
          </a>
          {'.'}
        </Paragraph>
      }
    >
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setInviteEmails(prevInviteEmails => [...prevInviteEmails, values.email])
          resetForm()
        }}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className="input-button teammate-email-input">
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Enter an email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                fullWidth={true}
                validation={true}
              />

              <Button size="medium" variant="gray-2" type="submit">
                Add teammate
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="invite-team--hero">
        <ListLabel>Teammates</ListLabel>

        <Divider dark={true} />

        <div className="teammate--list">
          {inviteEmails.length === 0 ? (
            <H3>
              You don’t have any right now <Emoji symbol="☹️" />
            </H3>
          ) : (
            <ul>
              {inviteEmails.map(email => (
                <ListItem key={email} className="onboarding--teammate-email">
                  <H3>{email}</H3>
                  <Button
                    variant="gray-1"
                    borderless={true}
                    size="small"
                    className="onboarding--teammate-email--close"
                    onClick={() => removeEmail(email)}
                  >
                    <Icon src={CloseIcon} alt="Close icon" />
                  </Button>
                </ListItem>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="onboarding-layout-buttons">
        {inviteEmails.length > 0 ? (
          <Button size="medium" variant="blue" onClick={sendInvitesHandler} disabled={sendingInvites}>
            {sendingInvites ? 'Sending' : 'Send Invites'}
          </Button>
        ) : null}

        <Link to="/install" style={{ textDecoration: 'none' }}>
          <Button variant="gray-1" size="medium" type="button">
            I work alone
          </Button>
        </Link>
      </div>
    </OnboardingLayout>
  )
}

export default OnboardingInviteTeam
