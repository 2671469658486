import firebase from 'firebase/app'
import 'firebase/firestore'
import { KonvaElement } from './konva'
import { IPreview } from './previews'

export interface IContainerInstance {
  X: number
  Y: number
  scale: number
  instanceId: string
}

export interface IContainer {
  containerId: string
  directoryId: string
  containerName: string
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
  instances: IContainerInstance[]
  previews: IPreview[]
  linked: boolean
  daemonId: string
  width: number
  height: number
}

export type ContainerTuple = [IContainer, IContainerInstance, KonvaElement]

export interface RightClickContainerTarget {
  kind: string
  target: IContainer
}

export function isContainer(val: any): val is IContainer {
  return !!(val && val.directoryId)
}
