import './Avatars.scss'

import React from 'react'

import {
  DEFAULT_USER_NAME,
  PARTICIPANT_PRESENCE_MS,
} from '../../../constants/moss'
import { Timestamp } from '../../../firebase'
import { IParticipant } from '../../../types/participant'
import { Paragraph, Tooltip } from '../../atoms'

interface IProps {
  participants: IParticipant[]
}

const Avatars = ({ participants }: IProps) => {
  const recentParticipants = participants.filter(
    participant => Timestamp.now().toMillis() - participant.lastSeenAt.toMillis() <= PARTICIPANT_PRESENCE_MS,
  )

  return (
    <ul className="avatars">
      {recentParticipants.map(participant => (
        <li className="avatar" key={participant.id}>
          <img src={participant.avatarSrc} alt="Avatar" />

          <div className="avatar__name">
            <Tooltip>
              <Paragraph>{participant.displayName || DEFAULT_USER_NAME}</Paragraph>
            </Tooltip>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Avatars
