import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import UserContext from '../../contexts/UserContext'
import { getUserMemberships, updateUserName } from '../../logic/firebase'
import { Button, Input, Paragraph, TextLink } from '../../ui/atoms'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'

const validationSchema = object({
  name: string().required('Required'),
})

const OnboardingName = () => {
  const user = useContext(UserContext)
  const history = useHistory()

  if (!user) return <OnboardingLayout mainHeading="There was an error getting user information"></OnboardingLayout>

  return (
    <OnboardingLayout
      mainHeading="Create your account"
      subHeading="This is how you’ll appear to your teammates in the app."
      footer={
        <Paragraph>
          By clicking “Create Account” above, you acknowledge that you have read and understood, and agree to Moss’{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/Terms-Privacy-60c44a64d798434fbc85b37bfcb7ecd0"
          >
            <TextLink>Terms of Service & Privacy Policy</TextLink>
          </a>
          {'.'}
        </Paragraph>
      }
    >
      <Formik
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateUserName(user, values.name)

            const querySnapshot = await getUserMemberships(user.uid)

            if (querySnapshot.size === 0) {
              history.push('/onboarding-create-team')
            } else {
              history.push('/install')
            }
          } catch (err) {
            console.log(err)
            toast.error('Something went wrong with creating your account')
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, handleBlur, handleChange, values }) => (
          <Form>
            <div className="onboarding-layout-hero">
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Enter your name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth={true}
                large={true}
                validation={true}
              />
              <Button type="submit" variant="blue" disabled={isSubmitting} fullWidth={true}>
                {isSubmitting ? 'Creating' : 'Create Account'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  )
}

export default OnboardingName
