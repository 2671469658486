import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import FoldersIcon from '../../assets/icons/file-folder_lightGray3.svg'
import ImagesIcon from '../../assets/icons/file-image_lightGray3.svg'
import VideosIcon from '../../assets/icons/file-video_lightGray3.svg'
import WeblinksIcon from '../../assets/icons/file-web_lightGray3.svg'
import HelpIcon from '../../assets/icons/help_darkGray1.svg'
import PsdIcon from '../../assets/icons/psd_color.svg'
import OnboardingVideo from '../../assets/videos/onboarding_demo_v002_compressed.mp4'
import UserContext from '../../contexts/UserContext'
import { firestore } from '../../firebase'
import { getUserMemberships } from '../../logic/firebase'
import { Button, Icon, Paragraph, SmallText, TextLink } from '../../ui/atoms/'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'
import './Install.scss'

const SUPPORTED_TOOLTIP_DATA = [
  {
    text: 'Folders',
    icon: FoldersIcon,
    alt: 'Folder icon',
  },
  {
    text: 'Images (png, jpg, tiff...)',
    icon: ImagesIcon,
    alt: 'Images icon',
  },
  {
    text: 'Vector (eps, svg)',
    icon: ImagesIcon,
    alt: 'Images icon',
  },
  {
    text: 'Photoshop (psd, psb)',
    icon: PsdIcon,
    alt: 'Photoshop icon',
  },
  // {
  //   text: 'Multipage (.pdf)',
  //   icon: MultipageIcon,
  //   alt: 'Multipage icon',
  // },
  {
    text: 'Videos (mp4, mov)',
    icon: VideosIcon,
    alt: 'Videos icon',
  },
  {
    text: 'Web Links (URL)',
    icon: WeblinksIcon,
    alt: 'Weblinks icon',
  },
]

const preInstallFooter = (
  <Paragraph>
    Learn more about the{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.notion.so/What-is-the-Moss-File-Syncer-7d4948af472e425f83bbee8b0dfaef55"
    >
      <TextLink>File Syncer</TextLink>
    </a>
    {'.'}
    <div className="line-break" />
    {'We use '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.notion.so/Acknowledgments-fab0e5598dc544d490842c5476ece627"
    >
      FFmpeg & ImageMagick
    </a>
    {'.'}
  </Paragraph>
)

const postInstallFooter = (
  <Paragraph>
    If you’re having trouble installing, check out the{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.notion.so/File-Syncer-troubleshooting-22566c2f929a4a989a1031e83f28e6b9"
    >
      <TextLink>troubleshooting doc</TextLink>
      {'.'}
    </a>
  </Paragraph>
)

const Install = () => {
  const [didDownload, setDidDownload] = useState(false)
  const user = useContext(UserContext)
  const history = useHistory()

  const downloadDaemon = () => {
    const anchor = document.createElement('a')
    anchor.href = '/moss.pkg'
    anchor.target = '_blank'
    anchor.download = 'moss.pkg'
    anchor.click()
    setDidDownload(true)
  }

  const continueButtonHandler = async () => {
    // TODO: show loading while this is happening

    if (!user) {
      console.log('User does not exist')
      toast.error('Something went wrong')
      return
    }

    try {
      await firestore.collection('users').doc(user.uid).update({
        onboardingInstallSyncerDone: true,
      })

      // Find users first canvas of first project
      // No obviously easy query, needs several steps
      // Probably a better way to do this
      const membershipsQuerySnapshot = await getUserMemberships(user.uid)

      if (membershipsQuerySnapshot.size === 0) {
        history.push('/')
        return
      }

      const projectsQuerySnapshot = await membershipsQuerySnapshot.docs[0].ref.parent.parent
        ?.collection('projects')
        .get()

      if (!projectsQuerySnapshot) {
        history.push('/')
        return
      }

      const projectRef = projectsQuerySnapshot?.docs[0].ref
      const canvasesQuerySnapshot = await firestore.collection('canvases').where('project', '==', projectRef).get()

      if (canvasesQuerySnapshot.size === 0) {
        history.push('/')
        return
      }

      history.push(`/canvas/${canvasesQuerySnapshot.docs[0].id}`)
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong')
      return
    }
  }

  return (
    <OnboardingLayout
      mainHeading={didDownload ? 'Hang tight, your download will start shortly' : 'Install the Moss File Syncer'}
      subHeading={
        didDownload
          ? "Don't worry about running the app. Once installed, the File Syncer will start itself and run in the background."
          : 'Files and folders synced to Moss are tethered to their source and update in realtime as you work.'
      }
      footer={didDownload ? postInstallFooter : preInstallFooter}
    >
      <div className="onboarding-layout-hero">
        <video src={OnboardingVideo} className="onboarding-video" autoPlay={true} loop={true} muted />

        <Button
          size="small"
          variant="gray-1"
          borderless={true}
          className="install-support"
          tooltip={
            <ul>
              {SUPPORTED_TOOLTIP_DATA.map(item => (
                <li key={item.text}>
                  <Icon src={item.icon} alt={item.alt} />
                  <Paragraph>{item.text}</Paragraph>
                </li>
              ))}
            </ul>
          }
          tooltipPos={'top'}
          tooltipDelay={false}
        >
          <Icon src={HelpIcon} alt="Help icon" />
          <SmallText>See what's supported</SmallText>
        </Button>
      </div>

      {didDownload ? (
        <div className="onboarding-layout-buttons">
          <Button variant="blue" size="medium" onClick={continueButtonHandler}>
            Continue to Moss
          </Button>
        </div>
      ) : (
        <div className="onboarding-layout-buttons">
          <Button variant="blue" size="medium" onClick={downloadDaemon}>
            &nbsp; Download for Mac
          </Button>

          <Button variant="gray-1" size="medium" onClick={continueButtonHandler}>
            I’ve already done this, take me to Moss
          </Button>
        </div>
      )}
    </OnboardingLayout>
  )
}

export default Install
