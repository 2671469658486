import firebase from 'firebase/app'
import 'firebase/firestore'
import { useEffect } from 'react'
import { arrayToObjectByProperty } from '../logic/util'
import { IContainer } from '../types/containers'
import { Firestore } from '../types/firebase'
import { ContainerIPreviews } from '../types/previews'

export function containersQuery(firestore: Firestore, canvasId: string) {
  return firestore.doc(`canvases/${canvasId}`).collection('containers')
}

export const useSetContainersData = (
  containers: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | null,
  containerPreviews: ContainerIPreviews,
  setContainersData: (data: { [key: string]: IContainer }) => void,
) => {
  return useEffect(() => {
    if (containers) {
      // @TODO I suspect that if we started keeping track of which container ID was updated we could skip some of this mapping
      const newData = containers
        .map(container => {
          const data = container.data() as IContainer
          if (data.instances.length) {
            data.containerId = container.id
            const mappedContainerPreviews = containerPreviews[data.containerId]
            if (mappedContainerPreviews) {
              data.previews = Object.keys(mappedContainerPreviews)
                .map(previewId => mappedContainerPreviews[previewId])
                .flat()
            }
            return data
          }

          return null
        })
        .filter(x => x) as IContainer[]
      setContainersData(arrayToObjectByProperty(newData, 'containerId'))
    }
  }, [containers, containerPreviews, setContainersData])
}
