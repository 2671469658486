import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer, cssTransition } from 'react-toastify'
import mixpanel from 'mixpanel-browser'

import { CurrentTeamProvider } from './contexts/CurrentTeamContext'
import { UserProvider } from './contexts/UserContext'
import { firestore, FirestoreContext } from './firebase'
import {
  Canvas,
  FinishSignIn,
  Install,
  InviteSeedUser,
  OnboardingCreateTeam,
  OnboardingInviteTeam,
  OnboardingName,
  SignIn,
} from './screens/'
import PrivateRoute from './ui/atoms/PrivateRoute/PrivateRoute'

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '')

Modal.setAppElement('#root')

const toastAnimation = cssTransition({
  collapseDuration: 500,
  enter: 'toast-in',
  exit: 'toast-out',
})

const App = observer(() => {
  return (
    <UserProvider>
      <FirestoreContext.Provider value={firestore}>
        <CurrentTeamProvider>
          <Router>
            <ToastContainer
              position="bottom-center"
              transition={toastAnimation}
              autoClose={4000}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              closeButton={false}
              draggable={false}
              limit={1}
            />
            <Switch>
              {/* TODO: Remove this route once beta is over, we won't need anymore */}
              <Route path="/invite-new-seed-user-create-team" exact>
                <InviteSeedUser />
              </Route>
              {/*  */}

              {/* TODO: update home page once ready, just sign in page for now */}
              <Route path="/" exact>
                <SignIn />
              </Route>
              <Route path="/sign-in" exact>
                <SignIn />
              </Route>
              <Route path="/finish-sign-in" exact>
                <FinishSignIn />
              </Route>
              <PrivateRoute path="/install" exact>
                <Install />
              </PrivateRoute>
              <PrivateRoute path="/canvas/:canvasId" exact>
                <Canvas />
              </PrivateRoute>
              <PrivateRoute path="/onboarding-name" exact>
                <OnboardingName />
              </PrivateRoute>
              <PrivateRoute path="/onboarding-create-team" exact>
                <OnboardingCreateTeam />
              </PrivateRoute>
              <PrivateRoute path="/onboarding-invite-team" exact>
                <OnboardingInviteTeam />
              </PrivateRoute>
            </Switch>
          </Router>
        </CurrentTeamProvider>
      </FirestoreContext.Provider>
    </UserProvider>
  )
})

export default App
