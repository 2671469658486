import './Card.scss'

import React from 'react'

interface ICommonProps {
  children: any
  className?: string
}
interface ICardProps extends ICommonProps {
  shadowless?: boolean
  transparentBackground?: boolean
}

interface ICardBodyProps extends ICommonProps {
  padding?: 'list' | 'form'
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xl'
  className?: string
}

const Card = ({ children, shadowless, transparentBackground, className }: ICardProps) => {
  return (
    <div
      className={`card ${shadowless ? 'card--shadowless' : ''} ${transparentBackground ? 'card--transparent' : ''} ${
        className || ''
      }`}
    >
      {children}
    </div>
  )
}

const CardBody = ({ size, children, padding = 'list', className }: ICardBodyProps) => {
  return <div className={`card-body card-body--${size} card-body--${padding} ${className || ''}`}>{children}</div>
}

const CardSection = ({ children }: ICommonProps) => {
  return <div className="card-section">{children}</div>
}

Card.Body = CardBody
Card.Section = CardSection

export default Card
