import { Form, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import FileIcon from '../../../assets/icons/add_localAsset.png'
import TextIcon from '../../../assets/icons/add_note.png'
import WeblinkIcon from '../../../assets/icons/add_webLink.png'
import LocalFileIcon from '../../../assets/icons/file-default_darkGray3.svg'
import LocalFolderIcon from '../../../assets/icons/file-folder_darkGray1.svg'
import FoldersIcon from '../../../assets/icons/file-folder_lightGray3.svg'
import ImagesIcon from '../../../assets/icons/file-image_lightGray3.svg'
import VideosIcon from '../../../assets/icons/file-video_lightGray3.svg'
import PsdIcon from '../../../assets/icons/psd_color.svg'
import { getZoomedAndScrolledPosition } from '../../../logic/canvasInteraction'
import { KonvaElement } from '../../../types/konva'
import WSC from '../../../WebSocketClient'
import { Button, Card, Divider, Icon, Input, ListItem, ListLabel, Paragraph, UnstyledButton } from '../../atoms'
import { MenuItem } from '../../molecules'
import './AddMenu.scss'

const SUPPORTED_TOOLTIP_DATA = [
  {
    text: 'Folders',
    icon: FoldersIcon,
    alt: 'Folder icon',
  },
  {
    text: 'Images (png, jpg, tiff...)',
    icon: ImagesIcon,
    alt: 'Images icon',
  },
  {
    text: 'Vector (eps, svg)',
    icon: ImagesIcon,
    alt: 'Images icon',
  },
  {
    text: 'Photoshop (psd, psb)',
    icon: PsdIcon,
    alt: 'Photoshop icon',
  },
  // {
  //   text: 'Multipage (.pdf)',
  //   icon: MultipageIcon,
  //   alt: 'Multipage icon',
  // },
  {
    text: 'Videos (mp4, mov)',
    icon: VideosIcon,
    alt: 'Videos icon',
  },
]

interface IProps {
  canvasId: string
  stageRef: React.RefObject<KonvaElement>
  zoom: number
  transparentBackround?: boolean
  shadowless?: boolean
  onFileDrop: (fileList: FileList | null, event: any) => void
  setDraftNote: React.Dispatch<React.SetStateAction<{ x: number; y: number } | null>>
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>
}

const AddMenu = ({
  canvasId,
  stageRef,
  zoom,
  transparentBackround,
  shadowless,
  onFileDrop,
  setDraftNote,
  setOpenMenu,
}: IProps) => {
  const [isWebPopoverOpen, setIsWebPopoverOpen] = useState(false)
  const [isLocalPopoverOpen, setIsLocalPopoverOpen] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const fileFormRef = useRef<any>()

  const onFileInputClick = () => {
    fileInputRef?.current?.click()
  }

  const submitFileForm = () => {
    fileFormRef?.current?.handleSubmit()
  }

  return (
    <Card transparentBackground={transparentBackround} shadowless={shadowless}>
      <Card.Body padding="list" size="large">
        <div>
          <ListLabel>Sync</ListLabel>

          <ul>
            <Popover
              isOpen={isLocalPopoverOpen}
              onClickOutside={() => setIsLocalPopoverOpen(false)}
              padding={-5}
              positions={['bottom']}
              align={'start'}
              content={
                <Card className="add-menu--popover dont-close-add-menu">
                  <Card.Body padding="list" size="small" className="dont-close-add-menu">
                    {/* File upload */}

                    <ListItem
                      size="medium"
                      className="dont-close-add-menu"
                      onClick={event => {
                        onFileInputClick()
                        toast('💡 You can also drop files & folders directly onto the canvas')
                      }}
                    >
                      <Icon src={LocalFileIcon} alt="File Icon" className="dont-close-add-menu" />
                      <Paragraph className="dont-close-add-menu">Sync files</Paragraph>
                    </ListItem>

                    <Formik
                      innerRef={fileFormRef}
                      enableReinitialize={true}
                      initialValues={{
                        files: [],
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false)
                        if (setOpenMenu) {
                          setOpenMenu(false)
                        }
                      }}
                    >
                      {({ handleChange, handleBlur, values, isSubmitting }) => (
                        <form>
                          <input
                            id="files"
                            name="files"
                            type="file"
                            ref={fileInputRef}
                            multiple
                            placeholder="Choose files"
                            onChange={event => {
                              const { files } = event.target
                              // compute drop location as the center of the window
                              const stageCenterX = stageRef.current.attrs.width / 2
                              const stageCenterY = stageRef.current.attrs.height / 2
                              if (onFileDrop) {
                                onFileDrop(files, {
                                  clientX: stageCenterX,
                                  clientY: stageCenterY,
                                })
                              }
                              submitFileForm()
                              setIsLocalPopoverOpen(false)
                            }}
                            value={values.files}
                            style={{ display: 'none' }}
                          />
                        </form>
                      )}
                    </Formik>

                    {/* Folder upload */}
                    <UnstyledButton tooltip="Drag & drop folders for now" tooltipDelay={false} noHover={true}>
                      <ListItem size="medium" className="dont-close-add-menu" disabled={true}>
                        <Icon src={LocalFolderIcon} alt="Folder Icon" className="dont-close-add-menu" />
                        <Paragraph className="dont-close-add-menu">Sync folder (drag & drop)</Paragraph>
                      </ListItem>
                    </UnstyledButton>

                    {/* TO DO: add form for folders. */}
                  </Card.Body>
                </Card>
              }
            >
              <div onClick={() => setIsLocalPopoverOpen(!isLocalPopoverOpen)}>
                <MenuItem
                  icon={FileIcon}
                  iconAlt="File icon"
                  heading={'Local Asset'}
                  description="Sync files & folders from your computer."
                  help={true}
                  helpMessage={
                    isLocalPopoverOpen ? (
                      ''
                    ) : (
                      <ul>
                        {SUPPORTED_TOOLTIP_DATA.map(item => (
                          <li key={item.text}>
                            <Icon src={item.icon} alt={item.alt} />
                            <Paragraph>{item.text}</Paragraph>
                          </li>
                        ))}
                      </ul>
                    )
                  }
                  tooltip={
                    isLocalPopoverOpen
                      ? ''
                      : 'Share reference and work in progress in real time. Changes to a synced file or folder are reflected in your workspace for your team to see. '
                  }
                />
              </div>
            </Popover>

            <Popover
              isOpen={isWebPopoverOpen}
              onClickOutside={() => setIsWebPopoverOpen(false)}
              padding={-5}
              positions={['bottom']}
              align={'start'}
              content={
                <Formik
                  enableReinitialize={true}
                  initialValues={{ url: '' }}
                  validationSchema={object({
                    url: string().required('Required'),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    // unlike Notes and Files, Weblinks need their final position
                    // calculated here because we send it to the backend and then
                    // render it at the calculated position on response from the
                    // backend whereas the former have their final positions
                    // calculated later by the canvas
                    const stageCenterX = stageRef.current.attrs.width / 2
                    const stageCenterY = stageRef.current.attrs.height / 2
                    const { x: stageOffsetX, y: stageOffsetY } = stageRef.current.position()
                    const [relativeX, relativeY] = getZoomedAndScrolledPosition(
                      [stageCenterX, stageCenterY],
                      [stageOffsetX, stageOffsetY],
                      zoom,
                    )
                    WSC.send(
                      JSON.stringify({
                        action: 'get_weblink',
                        data: {
                          canvasId,
                          url: values.url,
                          dropX: relativeX,
                          dropY: relativeY,
                        },
                      }),
                    )

                    // TODO: handle success state (reset form?)
                    // TODO: handle error state
                    setSubmitting(false)
                    if (setOpenMenu) {
                      setOpenMenu(false)
                    }
                  }}
                >
                  {({ handleChange, handleBlur, values, isSubmitting }) => (
                    <Form>
                      <Card className="add-menu--popover dont-close-add-menu">
                        <Card.Body size="large" className="input-button dont-close-add-menu">
                          <Input
                            id="url"
                            name="url"
                            type="text"
                            placeholder="Paste a URL"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.url}
                            fullWidth={true}
                            validation={true}
                            autoFocus={true}
                            className="dont-close-add-menu"
                          />

                          <Button
                            size="medium"
                            variant="gray-1"
                            type="submit"
                            disabled={isSubmitting}
                            className="dont-close-add-menu"
                          >
                            {isSubmitting ? 'Creating' : 'Create'}
                          </Button>
                        </Card.Body>
                      </Card>
                    </Form>
                  )}
                </Formik>
              }
            >
              <div onClick={() => setIsWebPopoverOpen(!isWebPopoverOpen)}>
                <MenuItem
                  icon={WeblinkIcon}
                  iconAlt="Weblink icon"
                  heading={'Web Link'}
                  description="Preview content from the web."
                  tooltip={
                    isWebPopoverOpen
                      ? ''
                      : 'Gather reference from around the web or link to online assets. Instagram, Pinterest, Vimeo, Figma, etc.'
                  }
                />
              </div>
            </Popover>
          </ul>

          <div>
            <Divider />
          </div>

          <ListLabel>Create</ListLabel>

          <ul>
            <div
              onClick={event => {
                // compute drop location as the center of the window
                const stageCenterX = stageRef.current.attrs.width / 2
                const stageCenterY = stageRef.current.attrs.height / 2
                if (setOpenMenu) {
                  setOpenMenu(false)
                }
                setDraftNote({ x: stageCenterX, y: stageCenterY })
              }}
            >
              <MenuItem
                icon={TextIcon}
                iconAlt="Text icon"
                heading={'Note'}
                description="Write on the canvas."
                tooltip={'Jot down an idea, leave feedback, ask questions.'}
              />
            </div>
          </ul>
        </div>
      </Card.Body>
    </Card>
  )
}

export default AddMenu
