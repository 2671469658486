import './Tools.scss'

import React, { Dispatch, SetStateAction } from 'react'

import cursor from '../../../assets/icons/cursor.svg'
import note from '../../../assets/icons/note.svg'
import { Button, Icon } from '../../atoms/'

interface Props {
  setCursor: (cursorType: string) => void
  clickMode: string
  setClickMode: Dispatch<SetStateAction<string>>
}

const Tools: React.FC<Props> = ({ setCursor, clickMode, setClickMode }) => {
  const getClasses = (buttonMode: string) => (buttonMode === clickMode ? 'tool-active' : 'white')
  return (
    <div className="tools">
      <Button
        size="small"
        variant={getClasses('default')}
        borderless={true}
        tooltip="Cursor"
        onClick={() => {
          setCursor('default')
          setClickMode('default')
        }}
      >
        <Icon src={cursor} alt={'Pointer Tool'} />
      </Button>
      <Button
        className="note-tool"
        size="small"
        variant={getClasses('note')}
        borderless={true}
        tooltip="Write a note"
        onClick={() => {
          setCursor('text')
          setClickMode('note')
        }}
      >
        <Icon src={note} alt={'Note Tool'} className="note-tool" />
      </Button>
    </div>
  )
}

export default Tools
