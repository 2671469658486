import './H3.scss'

import React from 'react'

interface IProps {
  children: any
}
const H3 = ({ children, ...props }: IProps) => (
  <h3 className={`h3`} {...props}>
    {children}
  </h3>
)

export default H3
