import { toast } from 'react-toastify'
import { MyFile } from '../types/files'
import { KonvaElement } from '../types/konva'
import WSC from '../WebSocketClient'
import { getZoomedAndScrolledPosition } from './canvasInteraction'
import mixpanel from 'mixpanel-browser'

const uploadFiles = ({
  fileList,
  event,
  canvasId,
  stageRef,
  zoom,
  localPreviewsDataRef,
  halfwayFilesRef,
}: {
  fileList: FileList | null
  event: DragEvent | { clientX: number; clientY: number }
  canvasId: string
  stageRef: React.RefObject<KonvaElement> // Konva Stage
  zoom: number
  localPreviewsDataRef: React.MutableRefObject<{ [key: string]: string }>
  halfwayFilesRef: React.MutableRefObject<{ [key: string]: MyFile }>
}) => {
  if (!fileList) {
    return
  }

  const files: any = []
  let fileCount: number = 0
  let folderCount: number = 0
  Array.from(fileList).forEach(file => {
    const myFile = file as MyFile
    if (myFile.type) {
      // https://stackoverflow.com/questions/922057/is-it-possible-to-preview-local-images-before-uploading-them-via-a-form
      localPreviewsDataRef.current[myFile.name] = URL.createObjectURL(file)
      fileCount++
    } else {
      folderCount++
    }
    files.push({
      lastModified: myFile.lastModified,
      lastModifiedDate: myFile.lastModifiedDate,
      fileName: myFile.name,
      size: myFile.size,
      type: myFile.type,
      path: myFile.path,
    })
    // store files until the backend confirms they have been processed or need halfway treatment
    halfwayFilesRef.current = { ...halfwayFilesRef.current, [myFile.name]: myFile }
  })
  const { x: stageOffsetX, y: stageOffsetY } = stageRef.current.position()
  const [relativeX, relativeY] = getZoomedAndScrolledPosition(
    [event.clientX, event.clientY],
    [stageOffsetX, stageOffsetY],
    zoom,
  )
  const message = {
    action: 'add_sync_targets',
    data: {
      files,
      canvasId: canvasId,
      dropX: relativeX,
      dropY: relativeY,
    },
  }

  mixpanel.track('File Drop', { 'File Count': fileCount, 'Folder Count': folderCount })

  //send off the list of files to the backend for instantiation
  WSC.send(JSON.stringify(message))
}

export const createOnFileDrop =
  ({
    connectedToServerRef,
    setDropAttempted,
    setIsLoading,
    canvasId,
    stageRef,
    zoom,
    localPreviewsDataRef,
    halfwayFilesRef,
  }: {
    connectedToServerRef: React.RefObject<boolean>
    stageRef: React.RefObject<KonvaElement> // Konva Stage
    setDropAttempted: (hasAttempted: boolean) => void
    setIsLoading: (isLoading: boolean) => void
    canvasId: string
    zoom: number
    localPreviewsDataRef: React.MutableRefObject<{ [key: string]: string }>
    halfwayFilesRef: React.MutableRefObject<{ [key: string]: MyFile }>
  }) =>
  (fileList: FileList | null, event: any) => {
    if (!connectedToServerRef.current) {
      setDropAttempted(true)
      toast(
        "Sorry, you are not connected to Moss. Either you have not installed our desktop app, or it has crashed and can't restart.",
      )
      return
    }
    if (fileList) {
      setIsLoading(true) // This is turned off in the onMessage handler
    }
    uploadFiles({ fileList, event, canvasId, stageRef, zoom, localPreviewsDataRef, halfwayFilesRef })
  }
