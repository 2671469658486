import firebase from 'firebase/app'
import mixpanel from 'mixpanel-browser'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import CanvasSVG from '../../../assets/icons/sidebar-canvas_darkGray3.svg'
import { NEW_CANVAS_NAME } from '../../../constants/moss'
import { FirestoreContext, ICanvas, Timestamp } from '../../../firebase'
import { SidebarItem, SidebarSectionHeader } from '../../molecules'

interface IProps {
  canvases: ICanvas[]
  selectedCanvasId: string
  selectedProject: firebase.firestore.DocumentReference
}

const SidebarCanvases = ({ canvases, selectedCanvasId, selectedProject }: IProps) => {
  const firestore = useContext(FirestoreContext)
  const history = useHistory()

  const newCanvasButtonHandler = async () => {
    try {
      const canvasRef = await firestore.collection('canvases').add({
        createdAt: Timestamp.now(),
        project: selectedProject,
        name: NEW_CANVAS_NAME,
      })

      mixpanel.track('Canvas Creation')

      history.push(`/canvas/${canvasRef.id}`)
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong')
    }
  }

  const onRenameSubmit = (canvasId: string) => async (name: string) => {
    await firestore.collection('canvases').doc(canvasId).update({
      name,
    })
  }

  const deleteCanvas = async (canvasId: string, currentCanvasId: string) => {
    // TODO: remove this restriction if we allow deletion of current canvas
    if (canvasId === currentCanvasId) {
      throw new Error("Can't delete your current canvas")
    }

    await firebase.functions().httpsCallable('deleteCanvas')({
      canvasId,
    })
  }

  return (
    <div className="sidebar-canvases">
      <SidebarSectionHeader title="Workspaces" onAddClick={newCanvasButtonHandler} />

      <ul>
        {canvases.map(canvas => (
          <SidebarItem
            key={canvas.id}
            itemName={canvas.name}
            selected={selectedCanvasId === canvas.id}
            iconSrc={CanvasSVG}
            iconAlt="Canvas icon"
            deleteAction={() => deleteCanvas(canvas.id, selectedCanvasId)}
            deleteDisabled={canvases.length === 1 || selectedCanvasId === canvas.id}
            onRenameSubmit={onRenameSubmit(canvas.id)}
            linkUrl={`/canvas/${canvas.id}`}
          />
        ))}
      </ul>
    </div>
  )
}

export default SidebarCanvases
