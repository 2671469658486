import './MenuItem.scss'

import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'

import { H3, ListItem, Paragraph, Tooltip, HelpTip } from '../../atoms'

interface IProps {
  icon: string
  iconAlt: string
  heading: any
  description: string
  tooltip?: any
  help?: boolean
  helpMessage?: any
}

const MenuItem = ({ icon, iconAlt, heading, description, help, helpMessage, tooltip }: IProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  if (tooltip) {
    return (
      <Popover
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={['right', 'left']}
        padding={5}
        content={<Tooltip className="tooltip--delayed">{tooltip}</Tooltip>}
      >
        <div
          onBlur={() => setIsPopoverOpen(false)}
          onFocus={() => setIsPopoverOpen(false)}
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
        >
          <ListItem size="large" className="menuItem">
            <img className="add__icon" src={icon} alt={iconAlt} width="36" height="36" />
            <div>
              <div className="menuItem-heading">
                <H3>{heading}</H3>
                {help ? <HelpTip gray={true}>{helpMessage ? helpMessage : ''}</HelpTip> : ''}
              </div>
              <Paragraph className="description">{description}</Paragraph>
            </div>
          </ListItem>
        </div>
      </Popover>
    )
  } else {
    return (
      <ListItem size="large" className="menuItem">
        <img className="add__icon" src={icon} alt={iconAlt} width="36" height="36" />
        <div>
          <div className="menuItem-heading">
            <H3>{heading}</H3>
            {help ? <HelpTip gray={true}>{helpMessage ? helpMessage : ''}</HelpTip> : ''}
          </div>
          <Paragraph className="description">{description}</Paragraph>
        </div>
      </ListItem>
    )
  }
}

export default MenuItem
