import './SquareLogo.scss'

import React from 'react'

interface IProps {
  logoSrc: string
  logoAlt: string
  size?: 'large' | 'medium' | 'small'
}

const SquareLogo = ({ logoSrc, logoAlt, size = 'medium' }: IProps) => {
  return <img alt={logoAlt} src={logoSrc} className={`square-logo square-logo--${size}`} />
}

export default SquareLogo
