import './CanvasInput.scss'

import React from 'react'

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const CanvasInput = React.forwardRef<HTMLInputElement, IProps>(({ ...restOfProps }: IProps, ref) => {
  return <input ref={ref} className={`canvas-input`} {...restOfProps} />
})

export default CanvasInput
