import './LoadingLayout.scss'

import React from 'react'
import { Flex } from 'reflexbox'
import { Icon } from '../../atoms'

import AnimatedLoadingIcon from '../../../assets/icons/menu-syncing.gif'

const LoadingLayout = () => {
  return (
    <Flex className="loading" alignItems="center" justifyContent="center" height="100%">
      <main>
        <Icon src={AnimatedLoadingIcon} alt="Loading icon" />
      </main>
    </Flex>
  )
}

export default LoadingLayout
