import './NewCanvasMenu.scss'

import firebase from 'firebase'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Box } from 'reflexbox'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import { NEW_CANVAS_NAME } from '../../../constants/moss'
import { AddMenu } from '../'
import { firestore } from '../../../firebase'
import { KonvaElement } from '../../../types/konva'
import { CanvasInput, Card, InputError, H3 } from '../../atoms'

interface IProps {
  canvasId: string
  canvasName: string
  canvasCreatedAtMillis: number
  stageRef: React.RefObject<KonvaElement>
  zoom: number
  onFileDrop: (fileList: FileList | null, event: any) => void
  setDraftNote: React.Dispatch<React.SetStateAction<{ x: number; y: number } | null>>
}

const NewCanvasMenu = ({
  canvasId,
  canvasName,
  canvasCreatedAtMillis,
  stageRef,
  zoom,
  onFileDrop,
  setDraftNote,
}: IProps) => {
  const nameRef = useRef<HTMLInputElement>(null)
  const [isNoteActive, setIsNoteActive] = useState<boolean>(false)

  useEffect(() => {
    // this doesn't do anything since nameRef.current is always null
    if (!nameRef.current) {
      return
    }

    const secondsSinceCreation = (firebase.firestore.Timestamp.now().toMillis() - canvasCreatedAtMillis) / 1000

    // Estimating first time user sees new canvas menu
    if (secondsSinceCreation <= 10) {
      nameRef.current.focus()
    } else {
      nameRef.current.blur()
    }
  }, [nameRef, canvasCreatedAtMillis])

  var target = Object(null)

  useEffect(() => {
    // slightly hacky, we want to prevent canvas actions, unless the note tool is active
    // this works, but could probably be done in a cleaner, more React-y way
    function handleClickOutside(event: MouseEvent) {
      target = event.target
      if (target.className.includes('note-tool')) {
        setIsNoteActive(!isNoteActive)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div className={isNoteActive ? 'new-canvas-wrapper--unlocked' : 'new-canvas-wrapper'}>
      <div className="new-canvas-menu">
        <Card.Body className="new-canvas-menu--text">
          <Formik
            enableReinitialize={true}
            initialValues={{ name: canvasName }}
            validationSchema={object({
              name: string().required('Required'),
            })}
            onSubmit={values => {
              firestore
                .collection('canvases')
                .doc(canvasId)
                .update({
                  name: values.name,
                })
                .catch(err => {
                  console.log(err)
                  toast.error('Something went wrong naming the canvas')
                })
            }}
          >
            {({ submitForm, handleBlur, handleChange, values }) => (
              <Form>
                <CanvasInput
                  ref={nameRef}
                  id="name"
                  name="name"
                  type="text"
                  placeholder={NEW_CANVAS_NAME}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    submitForm()
                  }}
                  value={values.name === NEW_CANVAS_NAME ? '' : values.name}
                  autoFocus={values.name === NEW_CANVAS_NAME ? true : false}
                />

                <ErrorMessage name="name">{msg => <InputError>{msg}</InputError>}</ErrorMessage>
              </Form>
            )}
          </Formik>

          <Box mt={['8px']}>
            <H3>Drop a file or folder to get started with a canvas.</H3>
          </Box>

          {/* <H3>Press tab to get back to this menu.</H3> */}
        </Card.Body>

        <Box mt={['26px']}>
          <AddMenu
            canvasId={canvasId}
            stageRef={stageRef}
            zoom={zoom}
            transparentBackround={true}
            shadowless={true}
            onFileDrop={onFileDrop}
            setDraftNote={setDraftNote}
          />
        </Box>
      </div>
    </div>
  )
}

export default NewCanvasMenu
