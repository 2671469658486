import React from 'react'
import HelpIcon from '../../../assets/icons/help_darkGray1.svg'
import { Button, Icon } from '../../atoms'
import './HelpTip.scss'

interface IProps {
  children?: any
  large?: boolean
  gray?: boolean
}
const HelpTip = ({ children, large, gray, ...props }: IProps) => {
  return (
    <Button
      size={large ? 'medium' : 'tiny'}
      variant={gray ? 'gray-1' : 'white'}
      borderless={true}
      className="help-tip"
      tooltip={children}
      tooltipPos={'bottom'}
      tooltipAlign={'start'}
      tooltipDelay={false}
    >
      <Icon src={HelpIcon} alt="Help icon" />
    </Button>
  )
}

export default HelpTip
