import './SyncStatus.scss'

import React from 'react'
import { Paragraph, Button, Icon } from '../../atoms'

import syncGif from '../../../assets/icons/menu-syncing.gif'

const SyncStatus = ({ classList }: { classList: string[] }) => {
  return (
    <Button size="medium" className={`${classList && classList.join(' ')} syncing-wrapper`}>
      <Icon src={syncGif} alt="Moss Logo Animated" />
      <Paragraph>Syncing...</Paragraph>
    </Button>
  )
}

export default SyncStatus
