import React from 'react'

interface IProps {
  symbol: string
  label?: string
}

const Emoji = ({ symbol, label }: IProps) => (
  <span className="emoji" role="img" aria-label={label ? label : ''} aria-hidden={label ? 'false' : 'true'}>
    {symbol}
  </span>
)
export default Emoji
