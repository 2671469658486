import { default as axios } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import DownloadIcon from '../../../assets/icons/arrow-bar-down_darkGray3.svg'
import { KonvaElement } from '../../../types/konva'
import { RightClickTarget } from '../../../types/moss'
import { isPreview } from '../../../types/previews'
import { Card, Icon, ListItem, ListLabel, Paragraph } from '../../atoms'
import './ContextMenu.scss'

interface IProps {
  contextMenuTarget: RightClickTarget
  setContextMenuTarget: React.Dispatch<React.SetStateAction<any>>
  setScrollingLocked: (shouldLock: boolean) => void
  canvasId: string
  setIsLoading: (isLoading: boolean) => void
  isOwned: boolean
  stageRef: React.RefObject<KonvaElement>
}

const ContextMenu = ({ contextMenuTarget, setContextMenuTarget, setScrollingLocked, stageRef }: IProps) => {
  // since we dont know the dimensions of the menu until it is rendered, draw it offscreen initially
  // then move it into place
  const [menuCoords, setMenuCoords] = useState<[number, number]>([-10000, -10000])
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // after offscreen render of the menu, make sure it is fully onscreen based on its dimensions
    const menuHeight = menuRef.current ? menuRef.current.offsetHeight : 0
    const menuWidth = menuRef.current ? menuRef.current.offsetWidth : 0
    // note might want to retrieve the pointer position prior to rendering, but GEFN
    const pointerTop = stageRef.current.getPointerPosition().y
    const pointerLeft = stageRef.current.getPointerPosition().x
    const top = Math.min(pointerTop, stageRef.current.attrs.height - menuHeight - 8)
    const left = Math.min(pointerLeft, stageRef.current.attrs.width - menuWidth - 8)
    setMenuCoords([top, left])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Prevent canvas scrolling and zooming while menu is open
  // Re-enable on editor close
  useEffect(() => {
    setScrollingLocked(true)
    return () => setScrollingLocked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const targetType = isPreview(contextMenuTarget) ? 'Preview' : 'Container'

  // NOTE: Code to resync disabled for now...
  // const resync = () => {
  //   let message
  //   if (isPreview(contextMenuTarget)) {
  //     message = {
  //       action: 'resync_item',
  //       data: {
  //         type: 'asset',
  //         target: contextMenuTarget.assetId,
  //       },
  //     }
  //   } else if (isContainer(contextMenuTarget)) {
  //     message = {
  //       action: 'resync_item',
  //       data: {
  //         type: 'container',
  //         canvasId,
  //         target: contextMenuTarget.directoryId,
  //       },
  //     }
  //   } else {
  //     toast.error('Sorry, an error occurred while trying to figure out what to update')
  //     return
  //   }
  //
  //   setIsLoading(true)
  //
  //   //send off the list of files to the backend for instantiation
  //   WSC.send(JSON.stringify(message))
  // }

  const download = () => {
    if (isPreview(contextMenuTarget)) {
      const nameParts = contextMenuTarget.previewName.split('.')
      if (nameParts.length > 1) {
        nameParts.pop()
      }

      let ext = ''
      if (nameParts.length > 1) {
        // if we have a previewName with periods in it then put an explicit
        // extension on the name or guess png if the mime_type is malformed
        ext = contextMenuTarget.mime_type.split('/')[1] || 'png'
      }

      const name = `${nameParts.join('.')}-preview${ext ? `.${ext}` : ''}`

      axios
        .get(contextMenuTarget.url, { responseType: 'blob' })
        .then(res => {
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(res.data)
          a.download = name
          document.body.append(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(a.href)
        })
        .catch(error => {
          toast.error('Sorry, an error occurred while trying to download')
          console.error(error)
        })
    }
  }

  return (
    <div id="context-menu" ref={menuRef} style={{ top: menuCoords[0] + 'px', left: menuCoords[1] + 'px' }}>
      <Card>
        <Card.Body padding="list" size="small">
          <ListLabel>Actions</ListLabel>
          <ListItem
            size="medium"
            onClick={() => {
              download()
              setContextMenuTarget(null)
            }}
          >
            <Icon src={DownloadIcon} alt="Download Icon" />
            <Paragraph>Download {targetType}</Paragraph>
          </ListItem>
          {/* <button
          disabled={!isOwned}
          onClick={() => {
            resync()
            setContextMenuTarget(null)
          }}
        >
          Update {targetType}
        </button> */}
        </Card.Body>
      </Card>
    </div>
  )
}

export default ContextMenu
