import Avatar1 from '../assets/avatars/avatar_1.jpg'
import Avatar2 from '../assets/avatars/avatar_2.jpg'
import Avatar3 from '../assets/avatars/avatar_3.jpg'
import Avatar4 from '../assets/avatars/avatar_4.jpg'
import Avatar5 from '../assets/avatars/avatar_5.jpg'
import Avatar6 from '../assets/avatars/avatar_6.jpg'
import Avatar7 from '../assets/avatars/avatar_7.jpg'
import Avatar8 from '../assets/avatars/avatar_8.jpg'
import Avatar9 from '../assets/avatars/avatar_9.jpg'
import Avatar10 from '../assets/avatars/avatar_10.jpg'
import Avatar11 from '../assets/avatars/avatar_11.jpg'
import TeamAvatar from '../assets/avatars/avatar-team-default.png'

export const DEFAULT_AVATARS = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
]

export const CONTAINER_META_HEIGHT = 60
export const NEW_CANVAS_NAME = 'New Canvas'

// if no connection for 15 Minutes, participant times out
export const PARTICIPANT_PRESENCE_MS = 900000

export const AVATAR_IMAGE_SIZE_LIMIT_BYTES = 2097152 // 2 mbs
export const TEAM_LOGO_SIZE_LIMIT_BYTES = 2097152 // 2 mbs

export const DEFAULT_TEAM_LOGO_URL = TeamAvatar
export const DEFAULT_USER_NAME = 'No name yet'
