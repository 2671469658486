import symmetricDifferenceWith from 'ramda/src/symmetricDifferenceWith'
import { toast } from 'react-toastify'

import { NOTE_MIME_TYPE } from '../constants/mimeTypes'
import { CONTAINER_META_HEIGHT } from '../constants/moss'
import { Firestore } from '../types/firebase'
import { AreaCoords, isPreviewCanvasMedia, MossMediaSelectionTuple, MossMediaTuple } from '../types/moss'
import { IPreview, IPreviewInstance } from '../types/previews'
import { IContainer } from '../types/containers'
import { addContainerInstance, addPreviewInstance } from './firebase'

export const createDeleteSelected = ({
  selectedObjectsRef,
  firestore,
  canvasId,
  setSelectedObjects,
  setActiveContainerInstance,
  setCursor,
}: {
  selectedObjectsRef: React.RefObject<MossMediaSelectionTuple[]>
  firestore: Firestore
  canvasId: string
  setSelectedObjects: (objects: []) => void
  setActiveContainerInstance: (instances: null) => void
  setCursor: (cursor: string) => void
}) => async () => {
  if (!selectedObjectsRef.current || selectedObjectsRef.current.length === 0) return

  try {
    // we need a hash to combine multiple instances of the same object
    // we need to know the object type and id, so use hash of hashes
    const objects: { [objectType: string]: { [objectId: string]: IPreview | IContainer } } = {
      previews: {},
      containers: {},
    }
    selectedObjectsRef.current.forEach((selectedObjectTuple: MossMediaSelectionTuple) => {
      let [object, instance] = selectedObjectTuple
      let objectType, objectId
      if (isPreviewCanvasMedia(object)) {
        objectType = 'previews'
        objectId = object.previewId
      } else {
        objectType = 'containers'
        objectId = object.containerId
      }
      if (objectId in objects[objectType]) object = objects[objectType][objectId]
      else objects[objectType][objectId] = object

      object.instances = object.instances.filter((curInstance: any) => curInstance.instanceId !== instance.instanceId)
    })

    // we now have a unique list of objects and are ready to update them
    const batch = firestore.batch()
    for (let objectType in objects) {
      for (let objectId in objects[objectType]) {
        const object = objects[objectType][objectId]
        const objectRef = firestore.doc(`canvases/${canvasId}`).collection(objectType).doc(objectId)
        batch.update(objectRef, {
          instances: object.instances,
        })
      }
    }

    await batch.commit()
  } catch (err) {
    toast.error('Error trying to update cloud data')
  }
  setSelectedObjects([])
  setActiveContainerInstance(null)
  setCursor('default')
}

export const createPasteSelected = ({
  selectedObjectsRef,
  firestore,
  canvasId,
  setSelectedObjects,
  setActiveContainerInstance,
  newMediaIdsRef,
}: {
  selectedObjectsRef: React.RefObject<MossMediaSelectionTuple[]>
  firestore: Firestore
  canvasId: string
  setSelectedObjects: (objects: []) => void
  setActiveContainerInstance: (instances: null) => void
  newMediaIdsRef: React.MutableRefObject<{ [key: string]: boolean }>
}) => async () => {
  if (!selectedObjectsRef.current || selectedObjectsRef.current.length === 0) return
  for (const selectedTuple of selectedObjectsRef.current) {
    const [media, instance] = selectedTuple
    if (isPreviewCanvasMedia(media)) {
      const { X: sourceX, Y: sourceY, scale, text, fontSize } = instance as IPreviewInstance
      addPreviewInstance(
        firestore,
        canvasId,
        media.previewId,
        {
          X: sourceX + 20,
          Y: sourceY + 20,
          ...(scale && { scale }),
          ...(text && { text }),
          ...(fontSize && { fontSize }),
        },
        newMediaIdsRef,
      )
    } else {
      addContainerInstance(firestore, canvasId, media.containerId, instance.X + 20, instance.Y + 20, instance.scale)
    }
  }
  setSelectedObjects([])
  setActiveContainerInstance(null)
}

const compareSelectedIds = (primaryTuple: MossMediaTuple, secondaryTuple: MossMediaTuple) => {
  const [primaryMedia, primaryInstance] = primaryTuple
  const [secondaryMedia, secondaryInstance] = secondaryTuple

  let matchedMediaIds = false
  if (isPreviewCanvasMedia(primaryMedia) && isPreviewCanvasMedia(secondaryMedia)) {
    matchedMediaIds = primaryMedia.previewId === secondaryMedia.previewId
  } else {
    matchedMediaIds = primaryMedia.containerId === secondaryMedia.containerId
  }
  return matchedMediaIds && primaryInstance.instanceId === secondaryInstance.instanceId
}

export const isInstanceSelected = (tuple: MossMediaTuple, selectedObjects: MossMediaSelectionTuple[]) =>
  selectedObjects.findIndex(selectedTuple => compareSelectedIds(selectedTuple, tuple)) !== -1

export const createUpdateSelectedObjects = ({
  selectedObjectsRef,
  setActiveContainerInstance,
  setSelectedObjects,
}: {
  selectedObjectsRef: React.MutableRefObject<MossMediaSelectionTuple[]>
  setActiveContainerInstance: (instances: null) => void
  setSelectedObjects: (objects: MossMediaSelectionTuple[]) => void
}) => (shouldCombine: boolean, incomingSelectedTuples: MossMediaSelectionTuple[], xOr: boolean = true) => {
  let newSelectedObjects
  if (shouldCombine) {
    // https://ramdajs.com/docs/#symmetricDifferenceWith
    // Creates a new array of tuples that combines current & incoming while
    // excluding any tuples that exist in both arrays
    if (xOr) {
      newSelectedObjects = symmetricDifferenceWith(
        (selected, incoming) => compareSelectedIds(selected, incoming),
        selectedObjectsRef.current,
        incomingSelectedTuples,
      )
    } else {
      const selectedObjects = selectedObjectsRef.current
      newSelectedObjects = [...selectedObjects, ...incomingSelectedTuples]
    }
  } else {
    newSelectedObjects = incomingSelectedTuples
  }
  setActiveContainerInstance(null)
  setSelectedObjects(newSelectedObjects)
}

export const computeMultiselectRect = (selectedObjects: MossMediaSelectionTuple[], zoom: number) => {
  const newCoords: AreaCoords = selectedObjects.reduce(
    (acc, obj, i) => {
      let mediaWidth
      let mediaHeight
      let mediaScale
      const element = obj[2]
      let { x, y } = element.attrs
      let elementPadding = 0
      if (isPreviewCanvasMedia(obj[0])) {
        if (obj[0].mime_type === NOTE_MIME_TYPE) {
          const { width, height } = obj[2].getClientRect()
          // getClientRect gives us the dimensions with zoom factored in
          // but we already scale the selectable area by zoom later
          // so we need to reverse the zoom calculation here or else it is zoomed twice
          mediaWidth = width / zoom
          mediaHeight = height / zoom
          mediaScale = 1
        } else {
          const { dimensions } = obj[0]
          const { scale } = obj[1]
          mediaWidth = dimensions[0]
          mediaHeight = dimensions[1]
          // Because we're pulling the media dimensions and not the
          // element dimensions, we need to adjust the select area
          y = y - 1 / zoom
          x = x - 1 / zoom
          elementPadding = 2 / zoom
          mediaScale = scale
        }
      } else {
        const { width, height } = obj[0]
        mediaWidth = width
        mediaHeight = height - CONTAINER_META_HEIGHT
        mediaScale = 1
      }
      const brX = x + mediaWidth * mediaScale
      const brY = y + mediaHeight * mediaScale
      if (i === 0) {
        return [x, y, brX + elementPadding, brY + elementPadding]
      }
      // Array order is top left x, top left y, bottom right x, bottom right y
      acc[0] = Math.min(acc[0], x)
      acc[1] = Math.min(acc[1], y)
      acc[2] = Math.max(acc[2], brX + elementPadding)
      acc[3] = Math.max(acc[3], brY + elementPadding)
      return [acc[0], acc[1], acc[2], acc[3]]
    },
    [0, 0, 0, 0],
  )
  return newCoords
}
