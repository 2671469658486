import firebase from 'firebase/app'
import mixpanel from 'mixpanel-browser'
import React, { createContext, useEffect, useState } from 'react'

interface IProps {
  children?: any
}

const USER_LOCAL_STORAGE_KEY = 'AUTH_USER'

const UserContext = createContext<firebase.User | null>(null)

const UserProvider = ({ children }: IProps) => {
  const [user, setUser] = useState<firebase.User | null>(
    localStorage.getItem(USER_LOCAL_STORAGE_KEY) && JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY) as string),
  )

  useEffect(() => {
    // returning for clean up
    return firebase.auth().onAuthStateChanged(userAuth => {
      if (userAuth) {
        localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(userAuth))
      } else {
        localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
      }

      setUser(userAuth)
    })
  }, [])

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.uid)
      mixpanel.people.set({
        // note $attr_name are mixpanel reserved profile attributes
        // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties#reserved-properties-for-user-profiles
        $distinct_id: user.uid,
        $email: user.email,
        $name: user.displayName,
      })
    }
  }, [user])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export default UserContext

export { UserProvider }
