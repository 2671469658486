import './UnstyledButton.scss'

import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { Tooltip } from '../../atoms'

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  fullWidth?: boolean
  textAlignLeft?: boolean
  displayBlock?: boolean
  tooltip?: string
  tooltipBottom?: boolean
  tooltipDelay?: boolean
  noHover?: boolean
}
const UnstyledButton = React.forwardRef<HTMLButtonElement, IProps>(
  (
    {
      fullWidth,
      textAlignLeft,
      displayBlock,
      className,
      tooltip,
      tooltipBottom,
      tooltipDelay = true,
      noHover,
      ...restOfProps
    },
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    if (tooltip) {
      return (
        <Popover
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
          positions={tooltipBottom ? ['bottom'] : ['right', 'left']}
          padding={5}
          boundaryInset={8}
          content={<Tooltip className={tooltipDelay ? 'tooltip--delayed' : ''}>{tooltip}</Tooltip>}
        >
          <div>
            <button
              ref={ref}
              className={`unstyled-button ${displayBlock ? 'unstyled-button--display-block' : ''} ${
                textAlignLeft ? 'unstyled-button--text-align-left' : ''
              } ${fullWidth ? 'unstyled-button--full' : ''} ${noHover ? 'unstyled-button--no-hover' : ''} ${
                className || ''
              }`}
              {...restOfProps}
              onBlur={() => setIsPopoverOpen(false)}
              onFocus={() => setIsPopoverOpen(false)}
              onMouseEnter={() => setIsPopoverOpen(true)}
              onMouseLeave={() => setIsPopoverOpen(false)}
            />
          </div>
        </Popover>
      )
    } else {
      return (
        <button
          ref={ref}
          className={`unstyled-button ${displayBlock ? 'unstyled-button--display-block' : ''} ${
            textAlignLeft ? 'unstyled-button--text-align-left' : ''
          } ${fullWidth ? 'unstyled-button--full' : ''} ${noHover ? 'unstyled-button--no-hover' : ''} ${
            className || ''
          }`}
          {...restOfProps}
        />
      )
    }
  },
)

export default UnstyledButton
