import './SmallText.scss'

import React from 'react'

interface IProps {
  children: any
}

const SmallText = ({ children }: IProps) => {
  return <span className={`small-text`}>{children}</span>
}

export default SmallText
