import './Modal.scss'

import React from 'react'
import ReactModal from 'react-modal'

interface IProps extends ReactModal.Props {
  children: any
  onRequestClose: () => void
  wide?: boolean
}

const Modal = ({ children, onRequestClose, wide, ...rest }: IProps) => (
  <ReactModal
    style={{
      overlay: {
        backgroundColor: 'rgba(18, 18, 18, .25)',
        overflowY: 'auto',
        zIndex: 1000,
      },
    }}
    className="modal-content"
    shouldCloseOnOverlayClick={true}
    onRequestClose={onRequestClose}
    {...rest}
  >
    {children}
  </ReactModal>
)

export default Modal
