import './ImageInput.scss'

import React, { useRef, useState } from 'react'
import { ErrorMessage } from 'formik'
import { Popover } from 'react-tiny-popover'
import { Flex } from 'reflexbox'

import { InputError, UnstyledButton } from '../../atoms'
import EditIcon from '../../../assets/icons/action-edit_white.svg'
import ImageDefault from '../../../assets/icons/add-image_darkGray2.svg'

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  initialImageUrl?: string
  imageAlt: string
  variant?: 'small' | 'normal'
  validation?: boolean
  id?: string
}

const ImageInput = ({
  variant = 'normal',
  initialImageUrl,
  imageAlt,
  accept,
  type,
  onChange,
  validation,
  id,
  ...rest
}: IProps) => {
  const [imageUrl, setImageUrl] = useState(initialImageUrl)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      setImageUrl(URL.createObjectURL(event.currentTarget.files[0]))
    }

    if (onChange) {
      onChange(event)
    }
  }

  const handleButtonClick = () => {
    if (!inputRef || !inputRef.current) {
      return
    }

    inputRef.current.click()
  }

  if (id && validation) {
    return (
      <Popover
        isOpen={true}
        padding={3}
        positions={['bottom']}
        align={'start'}
        content={<ErrorMessage name={id}>{msg => <InputError>{msg}</InputError>}</ErrorMessage>}
      >
        <div className={`image-input image-input--${variant}`}>
          <UnstyledButton
            className={imageUrl ? 'image-input__button--custom' : 'image-input__button--default'}
            onClick={handleButtonClick}
            type="button"
          >
            <img
              src={imageUrl || ImageDefault}
              alt={imageAlt}
              className={imageUrl ? 'image-input__preview--custom' : 'image-input__preview--default'}
            />
            <Flex alignItems="center" justifyContent="center" className="image-input__overlay">
              <img src={EditIcon} alt="Edit Icon" width="18" height="18" />
            </Flex>
          </UnstyledButton>

          <input
            ref={inputRef}
            className="image-input__input"
            accept="image/*"
            type="file"
            onChange={handleChange}
            {...rest}
          />
        </div>
      </Popover>
    )
  } else {
    return (
      <div className={`image-input image-input--${variant}`}>
        <UnstyledButton
          className={imageUrl ? 'image-input__button--custom' : 'image-input__button--default'}
          onClick={handleButtonClick}
          type="button"
        >
          <img
            src={imageUrl || ImageDefault}
            alt={imageAlt}
            className={imageUrl ? 'image-input__preview--custom' : 'image-input__preview--default'}
          />
          <Flex alignItems="center" justifyContent="center" className="image-input__overlay">
            <img src={EditIcon} alt="Edit Icon" width="18" height="18" />
          </Flex>
        </UnstyledButton>

        <input
          ref={inputRef}
          className="image-input__input"
          accept="image/*"
          type="file"
          onChange={handleChange}
          {...rest}
        />
      </div>
    )
  }
}

export default ImageInput
