import React from 'react'
import { toast } from 'react-toastify'
import WSC from '../WebSocketClient'
import { SetTimeout } from '../types/functions'
import { Firestore, FirebaseAddTargetMessage, AddTargetObject } from '../types/firebase'
import { IContainer } from '../types/containers'
import { IPreview } from '../types/previews'
import { addContainerInstance, getContainer, addPreviewInstance } from './firebase'
import { previewDefaultScale } from './previews'
import axios from 'axios'
import { MyFile } from '../types/files'

export const add_sync_targets_instances = ({
  firestore,
  newMediaIdsRef,
  halfwayFilesRef,
}: {
  firestore: Firestore
  newMediaIdsRef: React.MutableRefObject<{ [key: string]: boolean }>
  halfwayFilesRef: React.MutableRefObject<{ [key: string]: MyFile }>
}) => async (message: FirebaseAddTargetMessage) => {
  // if we get here, the backend added objects (directories, assets, containers and previews)
  const messageObjs = message.result.objects.reduce(
    (
      acc: {
        success: {
          directory?: AddTargetObject[]
          asset?: AddTargetObject[]
        }
        ambiguous: AddTargetObject[]
        not_found: AddTargetObject[]
        error: AddTargetObject[]
      },
      obj,
    ) => {
      const { status, target_type } = obj
      if (status === 'success') {
        if (!acc[status][target_type]) {
          acc[status][target_type] = []
        }
        acc[status][target_type]?.push(obj)
      } else {
        acc[status].push(obj)
      }
      return acc
    },
    { success: {}, ambiguous: [], error: [], not_found: [] },
  )

  // we passed where we dropped the load through the backend messaging
  let curX = message.data.dropX
  let curY = message.data.dropY
  const canvasId = message.data.canvasId

  // add instances for containers in a vertical list
  const directories = messageObjs.success.directory
  if (directories?.length) {
    for (const directory of directories) {
      const { containerId } = directory.data as IContainer
      addContainerInstance(firestore, canvasId, containerId, curX, curY, 1)
      const container: any = await getContainer(firestore, canvasId, containerId)
      curY += container.data().height
    }
  }

  // then add instances for individual previews in a grid
  let colMaxY = [curY, curY, curY, curY]
  let rowItemCount = 0
  const asset_targets = messageObjs.success.asset
  if (asset_targets?.length) {
    for (const asset_target of asset_targets) {
      const asset = asset_target.data
      const scale = previewDefaultScale(asset)
      const { previewId, dimensions } = asset as IPreview
      addPreviewInstance(firestore, canvasId, previewId, { X: curX, Y: curY, scale })
      colMaxY[rowItemCount] += dimensions[1] * scale + 20
      rowItemCount += 1
      if (rowItemCount === 4) {
        rowItemCount = 0
        curX = message.data.dropX
      } else {
        curX += 160
      }
      curY = colMaxY[rowItemCount]
    }
  }

  const notFound = messageObjs.not_found
  if (notFound.length) {
    const formData = new FormData()
    formData.set('canvasId', canvasId)
    const notFoundNames = notFound.map((msg: any) => msg['target']['fileName'])
    notFoundNames.forEach(fileName => {
      formData.append('upload', halfwayFilesRef.current[fileName])
      delete halfwayFilesRef.current[fileName]
    })
    axios({
      method: 'POST',
      url: `${message.result.halfway_server}/halfway-files`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        for (const asset of res.data.assets) {
          const scale = previewDefaultScale(asset)
          const { previewId, dimensions } = asset as IPreview
          addPreviewInstance(firestore, canvasId, previewId, { X: curX, Y: curY, scale })
          colMaxY[rowItemCount] += dimensions[1] * scale + 20
          rowItemCount += 1
          if (rowItemCount === 4) {
            rowItemCount = 0
            curX = message.data.dropX
          } else {
            curX += 160
          }
          curY = colMaxY[rowItemCount]
        }
      })
      .catch(error => {
        console.log('halfway file upload error: ', error)
        toast.warning('An error was encountered while processing the files.')
      })
  }

  const ambiguous = messageObjs.ambiguous
  if (ambiguous.length) {
    let jsx = (
      <div style={{ textAlign: 'left' }}>
        {ambiguous.map((msg: any) => (
          <>
            Warning: some items not added due to ambiguous paths
            <br /> <br />
            {msg['data']['fileName']}:<br />
            {msg['data']['paths'].map((path: string) => (
              <>
                <div style={{ width: 100 }}> </div>
                {path}
                <br />
              </>
            ))}
          </>
        ))}
      </div>
    )

    toast.warning(jsx)
  }

  const errors = messageObjs.error
  if (errors.length) {
    toast.warning(
      'An error was encountered when adding the following files.\n' +
        'Please try again and contact support if the problem persists:\n\n' +
        errors.map((msg: any) => {
          return `\n${msg['target']['fileName']}:\n`
        }),
    )
  }
}

export const onMessage = ({
  setIsLoading,
  add_sync_targets_instances,
  setDaemonId,
  add_weblink,
}: {
  setIsLoading: (isLoading: boolean) => void
  add_sync_targets_instances: (message: any) => void
  setDaemonId: (message: any) => void
  add_weblink: (message: any) => void
}) => (evt: MessageEvent) => {
  const message = JSON.parse(evt.data)

  const action = message.action
  const status = message.status

  if (status === 'error') {
    // if we get an error for a weblink we are still gonna create a placeholder
    if (action === 'get_weblink') {
      add_weblink(message)
      return
    }
    const msg = message['error'] || 'An unexpected problem was encountered.'
    toast.error(msg)
  } else if (action === 'add_sync_targets') {
    setIsLoading(false)
    add_sync_targets_instances(message)
  } else if (action === 'resync_item') {
    setIsLoading(false)
  } else if (action === 'get_daemonId') {
    setDaemonId(message['daemonId'])
  } else if (action === 'get_weblink') {
    add_weblink(message)
  } else if (action === 'link_asset_preview') {
    // nothing to do here?
  } else if (action === 'link_directory_container') {
    // nothing to do here?
  } else if (action === 'open_in_finder') {
    // nothing to do here?
  } else if (action === 'open_in_app') {
    // nothing to do here?
  } else {
    console.warn('ERROR: unhandled response from backend: ' + JSON.stringify(message, null, 4))
  }
}

export const onOpen = ({
  setConnectedToServer,
  reconnectTimerRef,
  setReconnectTimer,
  dropAttemptedRef,
  setDropAttempted,
}: {
  setConnectedToServer: (isConnected: boolean) => void
  reconnectTimerRef: React.RefObject<SetTimeout>
  setReconnectTimer: (timer: SetTimeout | null) => void
  dropAttemptedRef: React.RefObject<boolean>
  setDropAttempted: (dropAttempted: boolean) => void
}) => (evt: Event) => {
  setConnectedToServer(true)

  // if we have been trying to reconnect, shut the timer
  if (reconnectTimerRef.current) {
    clearTimeout(reconnectTimerRef.current)
    setReconnectTimer(null)
  }

  // if tried to take action while disconnected, show the user a message
  if (dropAttemptedRef.current) {
    setDropAttempted(false)
    toast('You have successfully reconnected to the server, feel free to add and edit files to the canvas')
  }

  // when initially connecting, get our daemonId for identifying what we own
  const getDaemonIdMessage = {
    action: 'get_daemonId',
  }
  WSC.send(JSON.stringify(getDaemonIdMessage))
}

export const onClose = ({
  setConnectedToServer,
  setReconnectTimer,
}: {
  setConnectedToServer: (isConnected: boolean) => void
  setReconnectTimer: (timer: SetTimeout | null) => void
}) => (evt: CloseEvent) => {
  setConnectedToServer(false)
  setReconnectTimer(
    setTimeout(() => {
      WSC.connect()
    }, 2000),
  )
}

export function onError(evt: Event) {
  console.warn('error: ' + evt + '\n' + JSON.stringify(evt))

  WSC.disconnect()
}
