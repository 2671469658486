import './TextLink.scss'

import React from 'react'

interface IProps {
  children: string
}

const TextLink = ({ children }: IProps) => {
  return <span className="text-link">{children}</span>
}

export default TextLink
