import { toast } from 'react-toastify'
import { Firestore } from '../types/firebase'
import { previewDefaultScale } from './previews'
import { createPreviewInstance } from './firebase'
import { WEBLINK_MIME_TYPE } from '../constants/mimeTypes'

const showWarning = () => {
  toast.warning('An error was encountered when retrieving the weblink preview.')
}

function trimProtocol(url: string) {
  return url.replace(/(^\w+:|^)\/\//, '')
}

export const add_weblink = (firestore: Firestore) => async (message: any) => {
  function createWeblinkPreview(dimensions: [number, number], url: string) {
    // we passed where we want the item dropped through the backend messaging
    let canvasX = message.data.dropX
    let canvasY = message.data.dropY

    // add a new preview object and instance
    createPreviewInstance({
      firestore,
      canvasId,
      X: canvasX,
      Y: canvasY,
      mime_type: WEBLINK_MIME_TYPE,
      url,
      dimensions,
      scale: previewDefaultScale({ dimensions }),
      previewName,
      weblinkUrl: message.data.url,
      favIcon,
    })
  }

  const canvasId = message.data.canvasId

  // start out with the title as the weblink url, in case we dont get an og:image
  let previewName = trimProtocol(message.data.url)
  let favIcon: string | undefined

  // if we get a backend error, create a placeholder preview
  if (message.error) {
    createWeblinkPreview([2000, 2000], '/images/weblink-bad.png')
    showWarning()
    return
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(message.result, 'text/html')
  const imgUrl = doc?.querySelector("meta[property='og:image']")?.getAttribute('content')
  favIcon = doc?.querySelector("link[rel~='icon']")?.getAttribute('href') || undefined
  // deal with relative favIcon variation
  // TODO: deal with other possible favIcon variations
  if (favIcon && !favIcon?.startsWith('http')) {
    if (favIcon?.startsWith('/')) {
      const url = new URL(message.data.url)
      favIcon = url.origin + favIcon
    } else {
      favIcon = undefined
    }
  }

  if (!imgUrl) {
    createWeblinkPreview([2000, 2000], '/images/weblink-bad.png')
    return
  }

  // title is either the og:title or the image url
  previewName = doc?.querySelector("meta[property='og:title']")?.getAttribute('content') || trimProtocol(imgUrl)

  // load the image to retrieve the dimensions
  const img = new Image()
  img.addEventListener(
    'error',
    function () {
      createWeblinkPreview([2000, 2000], '/images/weblink-bad.png')
    },
    false,
  )
  img.addEventListener(
    'load',
    function () {
      createWeblinkPreview([this.naturalWidth, this.naturalHeight], imgUrl)
    },

    false,
  )
  img.src = imgUrl
}
