import './Helper.scss'

import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'

import HelperSVG from '../../../assets/icons/helper.svg'
import InstagramIcon from '../../../assets/icons/instagram_darkgray1.svg'
import QuestionIcon from '../../../assets/icons/menu-help-large_darkGray3.svg'
import DiscordIcon from '../../../assets/icons/discord_darkGray3.svg'
import BookIcon from '../../../assets/icons/object-book_darkGray3.svg'
import CommentIcon from '../../../assets/icons/object-comment_darkGray3.svg'
import DocumentIcon from '../../../assets/icons/object-document_darkGray3.svg'
import TwitterIcon from '../../../assets/icons/twitter_darkgray1.svg'
import { Button, Card, Divider, Icon, ListItem, ListLabel, Paragraph } from '../../atoms/'

const HELP_ITEMS_DATA = [
  {
    icon: DiscordIcon,
    text: 'Live support',
    link: 'https://discord.gg/DaWFeK3zeH',
  },
  {
    icon: BookIcon,
    text: 'Knowledge base',
    link: 'https://www.notion.so/Moss-Knowledge-Base-Alpha-f5745f23dd1a4276bfc67b99eb45baa3',
  },
  {
    icon: QuestionIcon,
    text: 'Get in touch',
    link: 'https://www.notion.so/Get-in-Touch-5d5b56f52d0e49e388464c2e55bbd3fd',
  },
  {
    icon: CommentIcon,
    text: 'Feedback',
    link: 'https://www.notion.so/Feedback-d2c434eaad714dc1ab17c284a3f8608a',
  },
]

export default function Helper() {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Popover
      isOpen={menuOpen}
      onClickOutside={() => setMenuOpen(false)}
      positions={['top']}
      padding={5}
      align="end"
      content={
        <Card>
          <Card.Body padding="list" size="small">
            <ul>
              {HELP_ITEMS_DATA.map(itemData => (
                <li key={itemData.text}>
                  <a href={itemData.link} target="_blank" rel="noopener noreferrer" className="helper--link">
                    <ListItem size="medium">
                      <Icon src={itemData.icon} alt={`${itemData.text} icon`} />
                      <Paragraph>{itemData.text}</Paragraph>
                    </ListItem>
                  </a>
                </li>
              ))}
              <Divider />
              <li>
                <a
                  href="https://www.notion.so/Terms-Privacy-60c44a64d798434fbc85b37bfcb7ecd0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="helper--link"
                >
                  <ListItem size="medium">
                    <Icon src={DocumentIcon} alt="Document icon" />
                    <Paragraph>Terms & privacy</Paragraph>
                  </ListItem>
                </a>
              </li>
            </ul>

            <Divider />

            <ListLabel className="helper--social-links">
              <Button size="tiny" color="white" borderless={true}>
                <a href="https://twitter.com/mossworkspace" target="_blank" rel="noopener noreferrer">
                  <Icon src={TwitterIcon} alt="Twitter Icon" />
                </a>
              </Button>
              <Button size="tiny" color="white" borderless={true} className="helper--social-icon-2">
                <a href="https://www.instagram.com/mossworkspace/" target="_blank" rel="noopener noreferrer">
                  <Icon src={InstagramIcon} alt="Instagram Icon" />
                </a>
              </Button>
            </ListLabel>
            <ListLabel className="helper--version-number">version 00.50.10</ListLabel>
          </Card.Body>
        </Card>
      }
    >
      <div className="helperWrapper">
        <Button
          floating={true}
          variant="white"
          size="circle"
          onClick={() => setMenuOpen(oldMenuOpen => !oldMenuOpen)}
          tooltip={menuOpen ? '' : 'Feedback, Knowledge Base & external links'}
        >
          <Icon alt="help icon" src={HelperSVG} />
        </Button>
      </div>
    </Popover>
  )
}
