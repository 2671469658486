import './Button.scss'

import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { Tooltip } from '../../atoms'

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: 'circle' | 'medium' | 'small' | 'tiny'
  variant?: 'white' | 'gray-1' | 'gray-2' | 'blue' | 'tool-active'
  borderless?: boolean
  floating?: boolean
  fullWidth?: boolean
  tooltip?: any
  tooltipPos?: 'top' | 'bottom'
  tooltipAlign?: 'start' | 'end'
  tooltipDelay?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, IProps>(
  (
    {
      size = 'large',
      variant = 'white',
      borderless,
      floating,
      fullWidth,
      className,
      tooltip,
      tooltipAlign,
      tooltipPos,
      tooltipDelay = true,
      ...restOfProps
    },
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    if (tooltip) {
      return (
        <Popover
          isOpen={isPopoverOpen}
          onClickOutside={() => setIsPopoverOpen(false)}
          positions={tooltipPos ? [tooltipPos] : ['right', 'left']}
          padding={5}
          boundaryInset={8}
          align={tooltipAlign ? tooltipAlign : 'center'}
          content={<Tooltip className={tooltipDelay ? 'tooltip--delayed' : ''}>{tooltip}</Tooltip>}
        >
          <div className="button-wrapper">
            <button
              ref={ref}
              className={`button button--${size} button--${variant} ${borderless ? 'button--borderless' : ''} ${
                floating ? 'button--floating' : ''
              } ${fullWidth ? 'button--full' : ''} ${className ? className : ''}`}
              {...restOfProps}
              onBlur={() => setIsPopoverOpen(false)}
              onFocus={() => setIsPopoverOpen(false)}
              onMouseEnter={() => setIsPopoverOpen(true)}
              onMouseLeave={() => setIsPopoverOpen(false)}
            />
          </div>
        </Popover>
      )
    } else {
      return (
        <button
          ref={ref}
          className={`button button--${size} button--${variant} ${borderless ? 'button--borderless' : ''} ${
            floating ? 'button--floating' : ''
          } ${fullWidth ? 'button--full' : ''} ${className ? className : ''}`}
          {...restOfProps}
        />
      )
    }
  },
)

export default Button
