import './ListItem.scss'

import React from 'react'

interface IProps {
  children: any
  size?: 'small' | 'medium' | 'settings' | 'team-select' | 'large'
  selected?: boolean
  className?: string
  tag?: keyof JSX.IntrinsicElements
  onClick?: React.MouseEventHandler
  disabled?: boolean
}

const ListItem = ({
  tag: Wrapper = 'li',
  children,
  selected,
  size = 'small',
  className,
  onClick,
  disabled,
}: IProps) => {
  return (
    <Wrapper
      className={`list-item list-item--${size} ${selected ? 'list-item--selected' : ''} ${
        disabled ? 'list-item--disabled' : ''
      } ${className ? className : ''}`}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}

export default ListItem
