import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import CurrentTeamContext from '../../../contexts/CurrentTeamContext'
import UserContext from '../../../contexts/UserContext'
import { FirestoreContext } from '../../../firebase'
import { createProject } from '../../../logic/firebase'
import { Button, Card, Emoji, H3, Input } from '../../atoms'

interface IProps {
  onProjectCreated: () => void
}

const NewProject = ({ onProjectCreated }: IProps) => {
  const user = useContext(UserContext)
  const currentTeamContext = useContext(CurrentTeamContext)
  const firestore = useContext(FirestoreContext)
  const history = useHistory()

  return (
    <Card>
      <Card.Body size="medium" padding="form">
        <div style={{ textAlign: 'center' }}>
          <H3>
            <Emoji symbol="🌱" /> Create a new project
          </H3>
        </div>
        <Formik
          initialValues={{ name: '' }}
          validationSchema={object({
            name: string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            if (!currentTeamContext.currentTeam) {
              console.log('No current team set')
              toast.error('Something went wrong')
              return
            }

            if (!user) {
              console.log('No current user set')
              toast.error('Something went wrong')
              return
            }

            try {
              const canvasRef = await createProject(firestore, currentTeamContext.currentTeam.id, user.uid, values.name)
              history.push(`/canvas/${canvasRef.id}`)
              onProjectCreated()
            } catch (err) {
              console.log(err)
              toast.error('Something went wrong')
              setSubmitting(false)
            }
          }}
        >
          {({ handleChange, handleBlur, values, isSubmitting }) => (
            <Form>
              <div className="input-button">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name your project"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  validation={true}
                  fullWidth={true}
                />

                <Button type="submit" variant="blue" size="medium" disabled={isSubmitting}>
                  {isSubmitting ? 'Creating' : 'Create'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  )
}

export default NewProject
