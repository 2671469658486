import firebase from 'firebase/app'
import 'firebase/firestore'
import mixpanel from 'mixpanel-browser'
import { isEmpty } from 'ramda'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FileDrop } from 'react-file-drop'
import { Layer, Rect, Stage } from 'react-konva'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EMPTY_OBJ } from '../../constants/empty'
import { PARTICIPANT_PRESENCE_MS } from '../../constants/moss'
import { ZOOM_MAX, ZOOM_MIN } from '../../constants/position'
import CurrentTeamContext from '../../contexts/CurrentTeamContext'
import UserContext from '../../contexts/UserContext'
import { firestore, ICanvas, Timestamp } from '../../firebase'
import { useSetContainersData } from '../../hooks/containers'
import { useSetPreviewsData } from '../../hooks/previews'
import useInterval from '../../hooks/useInterval'
import { useWebsocketSetup } from '../../hooks/webSocket'
import {
  createOnStageMouseDown,
  createOnStageMouseMove,
  createOnStageMouseUp,
  createOnStageScroll,
  updateUrlLocation,
  useAddKeyboardListener,
} from '../../logic/canvasInteraction'
import {
  createCompleteContainerPreviewsDrag,
  createInitContainersPreviewDrag,
  nullDragContainerPreviews,
} from '../../logic/containers'
import { transformCanvasDocData, transformTeamData, transformUserDocDataToParticipant } from '../../logic/data'
import { createOnFileDrop } from '../../logic/fileUpload'
import { getUserMemberships } from '../../logic/firebase'
import { isNote, isVideo, previewDefaultScale } from '../../logic/previews'
import {
  computeMultiselectRect,
  createDeleteSelected,
  createPasteSelected,
  createUpdateSelectedObjects,
  isInstanceSelected,
} from '../../logic/selection'
import { clamp } from '../../logic/util'
import { IContainer, IContainerInstance, isContainer } from '../../types/containers'
import { MyFile } from '../../types/files'
import { KonvaElement, KonvaMouseEvt } from '../../types/konva'
import { AreaCoords, MossMediaSelectionTuple, RightClickTarget } from '../../types/moss'
import { IParticipant } from '../../types/participant'
import { ContainerIPreviews, IPreview, IPreviewInstance, isPreview } from '../../types/previews'
import { IProject } from '../../types/projects'
import { ITeam } from '../../types/teams'
import { AddSection, Avatars, ContextMenu, NewCanvasMenu, SyncStatus, Tools } from '../../ui/organisms'
import Helper from '../../ui/organisms/Helper'
import Sidebar from '../../ui/organisms/Sidebar'
import TopMenu from '../../ui/organisms/TopMenu'
import { LoadingLayout } from '../../ui/templates'
import WSC from '../../WebSocketClient'
import './Canvas.scss'
import { Container } from './Container'
import Note from './Note'
import SelectionTransformer from './SelectionTransformer'
import { URLImage } from './URLImage'
import { Video } from './Video'

function Canvas(props: any) {
  // @TODO Some of the top things here might not need to be state
  // changing them to refs would save us re-renders

  // for render optimization, keep count of renders
  // const rendercount = useRef<number>(0)

  // START - MAIN DEFS - START
  let { canvasId } = useParams<{ canvasId: string }>()
  const history = useHistory()
  const { uid: userUid } = useContext(UserContext) || {}
  const { search, pathname } = useLocation()
  const stageRef = useRef<any>(null)
  const portalTargetRef = useRef<HTMLDivElement>(null)
  const [contextMenuTarget, setContextMenuTarget] = useState<RightClickTarget | null>(null)

  const [dataSetupInProgress, setDataSetupInProgress] = useState(true)
  const [canvasesLoading, setCanvasesLoading] = useState(true)
  const [previewsLoading, setPreviewsLoading] = useState(true)
  const [containersLoading, setContainersLoading] = useState(true)
  const [projectsLoading, setProjectsLoading] = useState(true)
  const [participantsLoading, setParticipantsLoading] = useState(true)
  const [teams, setTeams] = useState<ITeam[]>([])
  const [projects, setProjects] = useState<IProject[]>([])
  const [currentCanvas, setCurrentCanvas] = useState<ICanvas>()
  const [canvases, setCanvases] = useState<ICanvas[]>([])
  const [previews, setPreviews] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>(
    [],
  )
  const [containers, setContainers] = useState<
    firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  >([])
  const [participants, setParticipants] = useState<IParticipant[]>([])
  const { setCurrentTeam } = useContext(CurrentTeamContext)

  // for multiselect, we need to be able to look up object refs based on their instanceIds
  // TODO: remove refs from the map when instances are deleted
  const [instanceRefs, _setinstanceRefs] = useState<Map<string, React.MutableRefObject<any>>>(
    new Map<string, React.MutableRefObject<any>>(),
  )
  const instanceRefsRef = useRef(instanceRefs)
  const setInstanceRefs = (data: Map<string, React.MutableRefObject<any>>) => {
    instanceRefsRef.current = data
    _setinstanceRefs(data)
  }
  const addToInstanceRefs = (instanceId: string, ref: React.MutableRefObject<any>) => {
    setInstanceRefs(new Map(instanceRefsRef.current.set(instanceId, ref)))
  }
  const getInstanceRef = (instanceId: string) => instanceRefsRef.current.get(instanceId)

  // for handling halfway file upload, we need a place to temporarily store file objects
  const halfwayFilesRef = useRef<{ [key: string]: MyFile }>({})

  // END - MAIN DEFS - END

  useEffect(() => {
    if (!userUid) {
      return
    }

    const userRef = firestore.collection('users').doc(userUid)
    const participantRef = firestore.collection('canvases').doc(canvasId).collection('participants').doc(userUid)

    userRef
      .update({
        currentCanvasId: canvasId,
      })
      .catch(err => {
        console.error('error updating current canvas id for user', err)
      })

    userRef
      .get()
      .then(doc => {
        const userData = doc.data()
        if (!userData) {
          throw new Error('Something went wrong finding user data')
        }
        return participantRef.set({
          userId: userUid,
          lastSeenAt: Timestamp.now(),
          ...(userData?.displayName && { displayName: userData?.displayName }),
        })
      })
      .catch(err => {
        console.error('error updating participant data for user', err)
      })
  }, [userUid, canvasId])

  useInterval(() => {
    firestore
      .collection('canvases')
      .doc(canvasId)
      .collection('participants')
      .doc(userUid)
      .update({
        lastSeenAt: Timestamp.now(),
      })
      .catch(err => {
        console.log(err)
      })
  }, PARTICIPANT_PRESENCE_MS / 2)

  useEffect(() => {
    let didCancel = false
    let canvasesUnsubscribe: undefined | (() => void)
    let projectsUnsubscribe: undefined | (() => void)
    let participantsUnsubscribe: undefined | (() => void)
    let previewsUnsubscribe: undefined | (() => void)
    let containersUnsubscribe: undefined | (() => void)

    if (!userUid) {
      return
    }

    const fetchData = async () => {
      setDataSetupInProgress(true)
      setCanvasesLoading(true)
      setPreviewsLoading(true)
      setParticipantsLoading(true)
      setContainersLoading(true)
      setProjectsLoading(true)
      // clear previewsData and the initial render flag so once the new previews data loads we kickoff background image loads
      setPreviewsData({})
      setInitialRender(true)

      try {
        const membershipsQuerySnapshot = await getUserMemberships(userUid)

        // Multiple reads b/c data is not denormalized
        // if we need to increase performance, or make queries simpler
        // we can de-normalize data
        const teamQuerySnapshots = await Promise.all(
          membershipsQuerySnapshot.docs.map(doc => doc.ref.parent.parent?.get()),
        )

        const retrievedTeams = teamQuerySnapshots.flatMap(teamQuerySnapshot => {
          if (!teamQuerySnapshot) {
            // TODO: should error be thrown?
            console.log('Could not find the team')
            return []
          }

          return [transformTeamData(teamQuerySnapshot)]
        })

        const canvasSnapshot = await firestore.collection('canvases').doc(canvasId).get()
        const canvasData = canvasSnapshot.data()

        if (!canvasSnapshot.exists || !canvasData) {
          console.log('Could not find the canvas data, searching for a good canvas')
          const firstTeamId = retrievedTeams[0].id
          const teamProjectRef = firestore.collection('teams').doc(firstTeamId)
          const projectCanvasesSnapshot = await firestore
            .collection('canvases')
            .where('project', '>', teamProjectRef)
            .get()
          console.log('number of project canvases: ', projectCanvasesSnapshot.size)
          const firstCanvasId = projectCanvasesSnapshot.docs[0].id
          console.log('first canvas id: ', firstCanvasId)
          const firstCanvas = projectCanvasesSnapshot.docs[0].data()
          toast.error(`Could not find the canvas, redirected to ${firstCanvas.name}`)
          history.replace(`/canvas/${firstCanvasId}`)
          return
        }

        const currentTeamId = canvasData.project.parent.parent?.id

        const currentTeamMembershipsSnapshot = await firestore
          .collection('teams')
          .doc(currentTeamId)
          .collection('memberships')
          .get()

        canvasesUnsubscribe = firestore
          .collection('canvases')
          .where('project', '==', canvasData.project)
          .onSnapshot(
            querySnapshot => {
              setCanvasesLoading(false)
              setCanvases(querySnapshot.docs.map(doc => transformCanvasDocData(doc)))

              const currentCanvasDoc = querySnapshot.docs.find(doc => doc.id === canvasId)

              if (!currentCanvasDoc) {
                console.log('Could not find current canvas')
                toast.error('Could not find the canvas. It may have been deleted')
                // TODO: show an error state on the page!
                // this can happen if your current canvas is deleted
                return
              }

              setCurrentCanvas(transformCanvasDocData(currentCanvasDoc))
            },
            err => {
              // TODO: how to display error listening
              console.log(err)
              toast.error('Something went wrong')
            },
          )

        previewsUnsubscribe = firestore
          .collection('canvases')
          .doc(canvasId)
          .collection('previews')
          .onSnapshot(
            querySnapshot => {
              setPreviewsLoading(false)
              setPreviews(querySnapshot.docs)
            },
            err => {
              // TODO: how to display error listening
              console.log(err)
              toast.error('Something went wrong')
            },
          )

        containersUnsubscribe = firestore
          .collection('canvases')
          .doc(canvasId)
          .collection('containers')
          .onSnapshot(
            querySnapshot => {
              setContainersLoading(false)
              setContainers(querySnapshot.docs)
            },
            err => {
              // TODO: how to display error listening
              console.log(err)
              toast.error('Something went wrong')
            },
          )

        projectsUnsubscribe = firestore
          .collection('teams')
          .doc(currentTeamId)
          .collection('projects')
          .onSnapshot(
            querySnapshot => {
              setProjectsLoading(false)
              setProjects(
                querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  name: doc.data().name,
                })),
              )
            },
            err => {
              // TODO: how to display error listening
              console.log(err)
              toast.error('Something went wrong')
            },
          )

        participantsUnsubscribe = firestore
          .collection('canvases')
          .doc(canvasId)
          .collection('participants')
          .onSnapshot(
            querySnapshot => {
              setParticipantsLoading(false)
              setParticipants(querySnapshot.docs.map(transformUserDocDataToParticipant))
            },
            err => {
              // TODO: how to display error listening
              console.log(err)
              toast.error('Something went wrong')
            },
          )

        if (!didCancel) {
          setCurrentTeam(retrievedTeams.find(team => team.id === currentTeamId) || null)
          setCurrentTeam(oldTeam =>
            Object.assign(oldTeam, {
              memberships: currentTeamMembershipsSnapshot.docs.map(doc => ({
                email: doc.data().email,
                admin: doc.data().admin,
                userId: doc.id,
              })),
            }),
          )
          setTeams(retrievedTeams)
        }
      } catch (err) {
        // TODO: figure out how to display error loading data
        console.log(err)
      } finally {
        setDataSetupInProgress(false)
      }
      //
    }

    fetchData()

    return () => {
      didCancel = true

      if (canvasesUnsubscribe) {
        canvasesUnsubscribe()
      }

      if (projectsUnsubscribe) {
        projectsUnsubscribe()
      }

      if (participantsUnsubscribe) {
        participantsUnsubscribe()
      }

      if (previewsUnsubscribe) {
        previewsUnsubscribe()
      }

      if (containersUnsubscribe) {
        containersUnsubscribe()
      }
    }
  }, [canvasId, userUid, setCurrentTeam])

  // ------------------------------
  // START - CANVAS STATE - START
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clickMode, setClickMode] = useState<string>('default')
  const isResizingRef = useRef<boolean>(false)
  const [scrollingLocked, setScrollingLocked] = useState<boolean>(false)
  const [isSidebarOpen, setOpenSidebar] = useState<boolean>(false)

  // Setting the cursor on hover of canvas element is done via
  // the Stage component, see https://konvajs.org/docs/styling/Mouse_Cursor.html
  const setCursor = (cursorType: string) => {
    if (stageRef.current) {
      stageRef.current.container().style.cursor = cursorType
    }
  }

  // allow access to selectedObjects from within even handlers
  // sorcery thanks to https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [selectedObjects, _setSelectedObjects] = useState<MossMediaSelectionTuple[]>([])
  const selectedObjectsRef = useRef(selectedObjects)
  const setSelectedObjects = (data: MossMediaSelectionTuple[]) => {
    selectedObjectsRef.current = data
    _setSelectedObjects(data)
  }
  // keep a queue of multiselect draggable objects for onDragEnd since each object gets its own event
  // this enables batch processing
  // TODO: proper typing [[contentsProps, newInstancesArray],...]
  const dragEndObjectsRef = useRef<any[]>([])

  // END - CANVAS STATE - END

  // ----------------------------
  // START - CANVAS DATA - START

  // this is the data for doing previews of objects right away, before uploading files to storage
  const localPreviewsDataRef = useRef<{ [key: string]: string }>(EMPTY_OBJ)

  const [activeContainerInstance, setActiveContainerInstance] = useState<[string, string] | null>()

  // setup hashes of the previews and containers data keyed off ID
  const [previewsData, setPreviewsData] = useState<{ [key: string]: IPreview }>({})
  const [containerPreviews, setContainerPreviews] = useState<ContainerIPreviews>({})
  const [containersData, setContainersData] = useState<{ [key: string]: IContainer }>({})
  useSetContainersData(containers, containerPreviews, setContainersData)
  useSetPreviewsData({
    setPreviewsData,
    setContainerPreviews,
    previews,
    containerPreviews,
    localPreviewsDataRef,
    containersData,
  })

  /*
    If you want to have media elements automatically select themselves
    upon instantiation, update the following ref with the instanceId
    of the thing you want to auto-select
  */
  const newMediaIdsRef = useRef<{ [key: string]: true }>({})
  const [draftNote, setDraftNote] = useState<{ x: number; y: number } | null>(null)
  const clearDraftNote = () => setDraftNote(null)
  // END - CANVAS DATA - END

  // we need to synchronize loading of large images to happen after initial render of the canvas using small images
  // when a new canvas is loaded, initialRender is set to true and previewsData is cleared
  // so this will only flag subcomponents to load large images once the previews data is loaded for a new canvas
  const [initialRender, setInitialRender] = useState(false)
  const [loadLargeImage, setLoadLargeImage] = useState(false)
  useEffect(() => {
    if (previewsData && initialRender) {
      setLoadLargeImage(true)
      setInitialRender(false)
    }
  }, [previewsData, initialRender])

  // --------------------------------
  // START - CANVAS SELECTION - START
  const updateActiveContainerInstance = (
    e: KonvaMouseEvt,
    container: IContainer,
    instance: IContainerInstance,
    element: KonvaElement,
  ) => {
    setActiveContainerInstance([container.containerId, instance.instanceId])
    setSelectedObjects([[container, instance, element]])
  }

  const deleteSelected = useCallback(
    createDeleteSelected({
      selectedObjectsRef,
      firestore,
      canvasId,
      setSelectedObjects,
      setActiveContainerInstance,
      setCursor,
    }),
    [firestore, canvasId],
  )

  const pasteSelected = useCallback(
    createPasteSelected({
      selectedObjectsRef,
      firestore,
      canvasId,
      setSelectedObjects,
      setActiveContainerInstance,
      newMediaIdsRef,
    }),
    [firestore, canvasId],
  )

  // this updates the list of selected objects, not the objects themselves
  const updateSelectedObjects = useCallback(
    createUpdateSelectedObjects({
      selectedObjectsRef,
      setActiveContainerInstance,
      setSelectedObjects,
    }),
    [selectedObjectsRef.current],
  )

  const mediaAddToSelected = useCallback(
    (isHoldingSpacebarRef: React.RefObject<boolean>) =>
      (e: KonvaMouseEvt, mediaTuple: MossMediaSelectionTuple, xOr?: boolean) => {
        if (isHoldingSpacebarRef.current) {
          return null
        }
        updateSelectedObjects(e.evt.shiftKey, [mediaTuple], xOr)
      },
    [updateSelectedObjects],
  )

  // END - CANVAS SELECTION - END

  // --------------------------------------
  // START - CONTROLS / INTERACTION - START

  const isHoldingSpacebarRef = useRef<boolean>(false)
  const isMouseDownRef = useRef<boolean>(false)

  // TODO this should no longer be necessary, but there is some complicated event order interaction with
  // editing Notes which breaks if this is removed
  const [sequencingHack, setSequencingHack] = useState<boolean>(false)
  const clearSelectArea = () => {
    setSequencingHack(false)
  }
  const doSequencingHack = !!sequencingHack
  useEffect(() => {
    if (doSequencingHack) {
      window.addEventListener('mouseout', clearSelectArea)
    } else {
      window.removeEventListener('mouseout', clearSelectArea)
    }
  }, [doSequencingHack])

  const [zoom, setZoom] = useState<number>(1)
  useEffect(() => {
    if (stageRef.current && search) {
      const position = new URLSearchParams(search)
      const [urlX, urlY, urlZ] = position.get('p')?.split(',') || []
      if (urlX && urlY && urlZ) {
        const initialX = clamp(parseFloat(urlX), -50000, 50000)
        const initialY = clamp(parseFloat(urlY), -50000, 50000)
        const initialZoom = clamp(parseFloat(urlZ), ZOOM_MIN, ZOOM_MAX)
        stageRef.current.scale({ x: initialZoom, y: initialZoom })
        setZoom(initialZoom)
        stageRef.current.position({ x: initialX, y: initialY })
        if (initialX !== parseFloat(urlX) || initialY !== parseFloat(urlY) || initialZoom !== parseFloat(urlZ)) {
          updateUrlLocation(history, initialX, initialY, initialZoom)
        }
      } else {
        history.replace(pathname)
      }
    }
  }, [stageRef.current])

  // for panning the stage, we need the mouse click coordinates along with stage position when clicked
  const [stagePanStartCoordinates, setStagePanStartCoordinates] = useState<[number, number] | null>(null)
  const stagePositionStartPanCoordinatesRef = useRef<[number, number] | null>(null)
  useEffect(() => {
    if (stageRef.current && stagePositionStartPanCoordinatesRef) {
      if (stagePanStartCoordinates === null) {
        stagePositionStartPanCoordinatesRef.current = null
      } else {
        const { x, y } = stageRef.current.position()
        stagePositionStartPanCoordinatesRef.current = [x, y]
      }
    }
  }, [stagePanStartCoordinates])

  // for dragging objects out of containers
  const [dragContainerPreviews, setDragContainerPreviews] = useState<any>(nullDragContainerPreviews)
  const [dragContainerPreviewsOffset, setDragContainerPreviewsOffset] = useState<[number, number] | null>(null)

  // for area multiselect, keep refs to the konva rectangle object representing the area, as well as state of the selection coords
  const dragSelectRectRef = useRef(null)
  const dragSelectCoordsRef = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  })

  // selectedAreaCoords stores the minimal rect containing all currently selected objects
  const [selectedAreaCoords, setSelectedAreaCoords] = useState<AreaCoords>()
  useEffect(() => {
    if (selectedObjects.length) {
      const newCoords: AreaCoords = computeMultiselectRect(selectedObjects, zoom)
      setSelectedAreaCoords(newCoords)
    } else {
      setSelectedAreaCoords(null)
    }
    // This gets updated on previews because after moving a preview
    // we need to update the selected area to the preview's new position
  }, [selectedObjects, previews, zoom])

  const onStageScroll = useCallback(createOnStageScroll(stageRef, setZoom, scrollingLocked, history), [scrollingLocked])
  const onStageMouseDown = useCallback(
    createOnStageMouseDown({
      isMouseDownRef,
      setStagePanStartCoordinates,
      isHoldingSpacebarRef,
      setCursor,
      zoom,
      stageRef,
      setSequencingHack,
      isResizingRef,
      setActiveContainerInstance,
      updateSelectedObjects,
      dragSelectRectRef,
      dragSelectCoordsRef,
    }),
    [isHoldingSpacebarRef, zoom],
  )

  const onStageMouseMove = useCallback(
    createOnStageMouseMove({
      isHoldingSpacebarRef,
      stagePanStartCoordinates,
      stageRef,
      dragContainerPreviews,
      setDragContainerPreviewsOffset,
      stagePositionStartPanCoordinatesRef,
      zoom,
      isMouseDownRef,
      dragSelectRectRef,
      dragSelectCoordsRef,
      history,
    }),
    [
      dragContainerPreviews,
      isHoldingSpacebarRef,
      stagePanStartCoordinates,
      zoom,
      isMouseDownRef,
      !!selectedObjects.length,
      selectedObjects,
    ],
  )
  // END - CONTROLS / INTERACTION- END

  // --------------------------
  // START - KEYBOARD EVENTS - START

  useAddKeyboardListener({
    stageRef,
    isHoldingSpacebarRef,
    isMouseDownRef,
    deleteSelected,
    pasteSelected,
    onStageScroll,
  })
  // END - KEYBOARD EVENTS - END

  // ------------------------------
  // handle messages coming from the local backend server

  // these state vars all need to be ref based since they are used in event handlers
  const [daemonId, _setDaemonId] = useState<string>('')
  const daemonIdRef = useRef(daemonId)
  const setDaemonId = (data: any) => {
    daemonIdRef.current = data
    _setDaemonId(data)
  }
  const [_dropAttempted, _setDropAttempted] = useState<boolean>(false)
  const dropAttemptedRef = useRef(_dropAttempted)
  const setDropAttempted = (data: any) => {
    dropAttemptedRef.current = data
    _setDropAttempted(data)
  }
  const [_reconnectTimer, _setReconnectTimer] = useState<any>()
  const reconnectTimerRef = useRef(_reconnectTimer)
  const setReconnectTimer = (data: any) => {
    reconnectTimerRef.current = data
    _setReconnectTimer(data)
  }
  const [_connectedToServer, _setConnectedToServer] = useState<boolean>(false)
  const connectedToServerRef = useRef(_connectedToServer)
  const setConnectedToServer = (data: any) => {
    connectedToServerRef.current = data
    _setConnectedToServer(data)
  }

  useWebsocketSetup({
    setIsLoading,
    setDaemonId,
    setConnectedToServer,
    reconnectTimerRef,
    setReconnectTimer,
    dropAttemptedRef,
    setDropAttempted,
    newMediaIdsRef,
    halfwayFilesRef,
  })

  // ------------------------------
  // START - DRAG AND DROP - START
  const onFileDrop = useCallback(
    createOnFileDrop({
      canvasId,
      connectedToServerRef,
      setIsLoading,
      setDropAttempted,
      stageRef,
      zoom,
      localPreviewsDataRef,
      halfwayFilesRef,
    }),
    [canvasId, zoom],
  )

  const initContainerPreviewsDrag = useCallback(
    createInitContainersPreviewDrag(stageRef, setDragContainerPreviews, zoom),
    [zoom],
  )

  const completeContainerPreviewsDrag = useCallback(
    createCompleteContainerPreviewsDrag({
      dragContainerPreviews,
      containers,
      stageRef,
      setDragContainerPreviews,
      zoom,
      canvasId,
      firestore,
      newMediaIdsRef,
    }),
    [dragContainerPreviews, containers, zoom, canvasId],
  )

  const onStageMouseUp = useCallback(
    createOnStageMouseUp({
      stagePanStartCoordinates,
      setStagePanStartCoordinates,
      isHoldingSpacebarRef,
      setCursor,
      dragContainerPreviews,
      completeContainerPreviewsDrag,
      isMouseDownRef,
      stageRef,
      updateSelectedObjects,
      previewsData,
      containersData,
      setActiveContainerInstance,
      zoom,
      isResizingRef,
      dragSelectRectRef,
      dragSelectCoordsRef,
      getInstanceRef,
    }),
    [isHoldingSpacebarRef, completeContainerPreviewsDrag, stagePanStartCoordinates, previewsData, containersData, zoom],
  )
  // END - DRAG AND DROP - END

  // ------------------------------
  // START - Right Click Context Menu

  const onRightClick = (e: KonvaMouseEvt, targetObject: RightClickTarget) => {
    let target = targetObject
    if (isContainer(target)) {
      // this seems wrong - right clicks should be handled at the preview level
      //   in the container like regular/left clicks...
      const previewContainerId: string = e.target.parent?.attrs?.id || ''
      const preview: IPreview | undefined = target.previews.find(prev => previewContainerId.includes(prev.previewId))
      if (preview) {
        target = preview
      }
    }
    if (isPreview(target)) {
      e.evt.preventDefault()
      setContextMenuTarget(target)
    }
  }

  // ----------------------------------------
  // open in finder / open in app
  const onOpenObject = (event: any, targetObject: any, inApp: boolean) => {
    if (!connectedToServerRef.current) {
      setDropAttempted(true)
      toast(
        "Sorry, you are not connected to Moss. Either you have not installed our desktop app, or it has crashed and can't restart.",
      )
      return
    }
    if (!targetObject) {
      return
    }
    let message
    if (targetObject.hasOwnProperty('assetId')) {
      message = {
        action: inApp ? 'open_in_app' : 'open_in_finder',
        data: {
          assetId: targetObject.assetId,
        },
      }
      mixpanel.track(inApp ? 'File Open In App' : 'File Open In Finder', { AssetId: targetObject.assetId })
    } else if (targetObject.hasOwnProperty('directoryId')) {
      message = {
        action: 'open_in_finder',
        data: {
          directoryId: targetObject.directoryId,
        },
      }
      mixpanel.track('Folder Open In Finder', { DirectoryId: targetObject.directoryId })
    } else {
      toast.error('Sorry, an error occurred while trying to figure out what to open')
      return
    }

    //send off the list of files to the backend for instantiation
    WSC.send(JSON.stringify(message))
  }

  // --------------------------
  // variables to scale text
  const fontSize = 11 / zoom
  const iconOuterWidth = 22 / zoom
  const iconPadding = 5 / zoom
  const iconWidth = 12 / zoom
  const paddingTop = 8 / zoom
  const minTextWidth = 80

  /* TODO: figure out UI for error occurring when loading data */
  const currentProjectName = (allProjects: IProject[], currentProjectId: string) => {
    const currentProject = allProjects.find(project => project.id === currentProjectId)
    return currentProject ? currentProject.name : 'No name project'
  }

  // TODO: do you need to include "dragContainerPreviews"
  const emptyCanvas = isEmpty(previewsData) && !draftNote && isEmpty(containersData)

  /* TODO: figure out proper data loading UI */
  if (
    dataSetupInProgress ||
    canvasesLoading ||
    previewsLoading ||
    participantsLoading ||
    containersLoading ||
    projectsLoading ||
    !currentCanvas
  ) {
    return <LoadingLayout />
  }

  // Clear all toasts in favor of sync status
  if (isLoading) {
    toast.dismiss()
  }

  // console.log('rendering', rendercount.current)
  // rendercount.current += 1
  return (
    <div>
      <div ref={portalTargetRef} />

      {contextMenuTarget && (
        <ContextMenu
          stageRef={stageRef}
          contextMenuTarget={contextMenuTarget}
          setContextMenuTarget={setContextMenuTarget}
          setScrollingLocked={setScrollingLocked}
          canvasId={canvasId}
          setIsLoading={setIsLoading}
          isOwned={daemonId === contextMenuTarget.daemonId}
        />
      )}

      <SyncStatus classList={isLoading ? ['syncing'] : []} />
      <Helper />
      <Avatars participants={participants} />
      <Tools setCursor={setCursor} clickMode={clickMode} setClickMode={setClickMode} />
      <AddSection
        canvasId={canvasId}
        stageRef={stageRef}
        zoom={zoom}
        onFileDrop={onFileDrop}
        setDraftNote={setDraftNote}
      />
      <TopMenu
        isSidebarOpen={isSidebarOpen}
        setOpenSidebar={setOpenSidebar}
        selectedCanvasName={currentCanvas.name}
        selectedProjectName={currentProjectName(projects, currentCanvas.project.id)}
      />

      <Sidebar
        projects={projects}
        teams={teams}
        canvases={canvases}
        isSidebarOpen={isSidebarOpen}
        selectedProject={currentCanvas.project}
        selectedCanvasId={currentCanvas.id}
      />
      <FileDrop onDrop={onFileDrop}>
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          ref={stageRef}
          onWheel={onStageScroll}
          onClick={(e: KonvaMouseEvt) => {
            if (contextMenuTarget) setContextMenuTarget(null)

            if (clickMode === 'note') {
              setClickMode('default')
              setDraftNote({ x: e.evt.clientX, y: e.evt.clientY })
              setCursor('default')
            }
          }}
          onMouseDown={onStageMouseDown}
          onMouseMove={onStageMouseMove}
          onMouseUp={onStageMouseUp}
          onContextMenu={e => {
            // suppress browser context menu
            // e.evt.preventDefault()
          }}
          id="stage"
        >
          <Layer>
            <SelectionTransformer selectedObjects={selectedObjects} selectedAreaCoords={selectedAreaCoords}>
              {Object.keys(previewsData).map((key: string) => {
                const preview = previewsData[key]
                return preview?.instances?.map((instance: IPreviewInstance, idx: number) => {
                  if (isVideo(preview)) {
                    return (
                      <Video
                        canvasId={canvasId}
                        preview={preview}
                        instance={instance}
                        isLinked={preview.linked}
                        readOnly={preview.daemonId !== daemonIdRef.current}
                        X={instance.X}
                        Y={instance.Y}
                        scale={instance.scale}
                        width={preview.dimensions[0]}
                        height={preview.dimensions[1]}
                        name={preview.previewName}
                        src={preview.url}
                        previewSrc={preview.uploadPreviewUrl}
                        updatedAt={preview.updatedAt}
                        key={instance.instanceId}
                        inContainer={false}
                        mediaAddToSelected={mediaAddToSelected(isHoldingSpacebarRef)}
                        selected={isInstanceSelected([preview, instance], selectedObjects)}
                        isHoldingSpacebarRef={isHoldingSpacebarRef}
                        fontSize={fontSize}
                        iconOuterWidth={iconOuterWidth}
                        iconPadding={iconPadding}
                        iconWidth={iconWidth}
                        paddingTop={paddingTop}
                        minTextWidth={80}
                        zoom={zoom}
                        onOpenObject={onOpenObject}
                        newMediaIdsRef={newMediaIdsRef}
                        isMultiSelect={selectedObjects.length > 1}
                        addToInstanceRefs={addToInstanceRefs}
                        selectedObjectsRef={selectedObjectsRef}
                        dragEndObjectsRef={dragEndObjectsRef}
                        onRightClick={onRightClick}
                      />
                    )
                  } else if (isNote(preview)) {
                    return (
                      <Note
                        x={instance.X}
                        y={instance.Y}
                        portalTargetRef={portalTargetRef}
                        canvasId={canvasId}
                        key={instance.instanceId}
                        instance={instance}
                        preview={preview}
                        selected={isInstanceSelected([preview, instance], selectedObjects)}
                        mediaAddToSelected={mediaAddToSelected(isHoldingSpacebarRef)}
                        zoom={zoom}
                        stagePosition={stageRef.current?.position()}
                        setScrollingLocked={setScrollingLocked}
                        isHoldingSpacebarRef={isHoldingSpacebarRef}
                        newMediaIdsRef={newMediaIdsRef}
                        selectedObjectCount={selectedObjects.length}
                        isMultiSelect={selectedObjects.length > 1}
                        addToInstanceRefs={addToInstanceRefs}
                        selectedObjectsRef={selectedObjectsRef}
                        dragEndObjectsRef={dragEndObjectsRef}
                      />
                    )
                  } else {
                    return (
                      <URLImage
                        canvasId={canvasId}
                        preview={preview}
                        instance={instance}
                        isLinked={preview.linked}
                        readOnly={preview.daemonId !== daemonIdRef.current}
                        X={instance.X}
                        Y={instance.Y}
                        scale={instance.scale}
                        width={preview.dimensions[0]}
                        height={preview.dimensions[1]}
                        name={preview.previewName}
                        src={preview.url}
                        previewSrc={preview.uploadPreviewUrl}
                        updatedAt={preview.updatedAt}
                        key={instance.instanceId}
                        inContainer={false}
                        mediaAddToSelected={mediaAddToSelected(isHoldingSpacebarRef)}
                        selected={isInstanceSelected([preview, instance], selectedObjects)}
                        isHoldingSpacebarRef={isHoldingSpacebarRef}
                        fontSize={fontSize}
                        iconOuterWidth={iconOuterWidth}
                        iconPadding={iconPadding}
                        iconWidth={iconWidth}
                        paddingTop={paddingTop}
                        minTextWidth={80}
                        zoom={zoom}
                        onOpenObject={onOpenObject}
                        newMediaIdsRef={newMediaIdsRef}
                        isMultiSelect={selectedObjects.length > 1}
                        addToInstanceRefs={addToInstanceRefs}
                        loadLargeImage={loadLargeImage}
                        selectedObjectsRef={selectedObjectsRef}
                        dragEndObjectsRef={dragEndObjectsRef}
                        onRightClick={onRightClick}
                      />
                    )
                  }
                })
              })}

              {draftNote && (
                <Note
                  x={draftNote.x}
                  y={draftNote.y}
                  clearDraftNote={clearDraftNote}
                  portalTargetRef={portalTargetRef}
                  canvasId={canvasId}
                  preview={null}
                  instance={null}
                  startWithEditOpen
                  zoom={zoom}
                  stagePosition={stageRef.current?.position()}
                  setScrollingLocked={setScrollingLocked}
                  isHoldingSpacebarRef={isHoldingSpacebarRef}
                  selectedObjectsRef={selectedObjectsRef}
                  dragEndObjectsRef={dragEndObjectsRef}
                />
              )}
              {containersData &&
                Object.keys(containersData).map((key: string) => {
                  const container = containersData[key]
                  return container?.instances?.map((instance: IContainerInstance, idx: number) => (
                    <Container
                      daemonId={daemonIdRef.current}
                      canvasId={canvasId}
                      container={container}
                      instance={instance}
                      linked={container.linked}
                      readOnly={container.daemonId !== daemonIdRef.current}
                      X={instance.X}
                      Y={instance.Y}
                      key={instance.instanceId}
                      containerInstanceId={instance.instanceId}
                      mediaAddToSelected={mediaAddToSelected(isHoldingSpacebarRef)}
                      selected={isInstanceSelected([container, instance], selectedObjects)}
                      onPreviewClick={updateActiveContainerInstance}
                      activeContainerInstance={activeContainerInstance}
                      initContainerPreviewsDrag={initContainerPreviewsDrag}
                      fontSize={fontSize}
                      iconOuterWidth={iconOuterWidth}
                      iconPadding={iconPadding}
                      iconWidth={iconWidth}
                      paddingTop={paddingTop}
                      minTextWidth={minTextWidth}
                      zoom={stageRef?.current?.scaleX()}
                      onOpenObject={onOpenObject}
                      isHoldingSpacebarRef={isHoldingSpacebarRef}
                      newMediaIdsRef={newMediaIdsRef}
                      addToInstanceRefs={addToInstanceRefs}
                      loadLargeImage={loadLargeImage}
                      selectedObjectsRef={selectedObjectsRef}
                      dragEndObjectsRef={dragEndObjectsRef}
                      onRightClick={onRightClick}
                    />
                  ))
                })}
              {/* @TODO Are there more drag container preview types besides images? */}
              {dragContainerPreviews?.previews?.map((preview: IPreview, idx: number) => {
                const { containerX, containerY } = preview
                if (!dragContainerPreviewsOffset || !(containerX && containerY)) return null
                const x = containerX + dragContainerPreviewsOffset[0]
                const y = containerY + dragContainerPreviewsOffset[1]
                const height = preview.dimensions[1]
                const width = preview.dimensions[0]
                if (!(x && y && height && width)) return null
                return (
                  <URLImage
                    canvasId={canvasId}
                    // @TODO These props might not be necessary
                    iconOuterWidth={iconOuterWidth}
                    iconWidth={iconWidth}
                    paddingTop={paddingTop}
                    minTextWidth={minTextWidth}
                    iconPadding={iconPadding}
                    fontSize={fontSize}
                    // End @TODO

                    preview={preview}
                    instance={null}
                    X={x}
                    Y={y}
                    width={width}
                    height={height}
                    name={preview.previewName}
                    key={idx}
                    scale={previewDefaultScale(preview)}
                    src={preview.url}
                    updatedAt={preview.updatedAt}
                    selected={true}
                    addToInstanceRefs={addToInstanceRefs}
                    isHoldingSpacebarRef={isHoldingSpacebarRef}
                    selectedObjectsRef={selectedObjectsRef}
                    dragEndObjectsRef={dragEndObjectsRef}
                  />
                )
              })}

              <Rect
                fill="rgba(43, 43, 43, .05)"
                stroke="rgba(217, 217, 217, 1)"
                strokeScaleEnabled={false}
                strokeWidth={1}
                ref={dragSelectRectRef}
                cornerRadius={2}
              />
            </SelectionTransformer>
          </Layer>
        </Stage>
      </FileDrop>

      {emptyCanvas && (
        <NewCanvasMenu
          canvasId={currentCanvas.id}
          canvasName={currentCanvas.name}
          canvasCreatedAtMillis={currentCanvas.createdAt.toMillis()}
          stageRef={stageRef}
          zoom={zoom}
          onFileDrop={onFileDrop}
          setDraftNote={setDraftNote}
        />
      )}
    </div>
  )
}

export default Canvas
