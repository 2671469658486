// A single method to detect if we are in Electron in either the main process or renderer processes.
//   Modified from https://github.com/cheton/is-electron/blob/master/index.js
//   See: https://github.com/electron/electron/issues/2288
export function isElectron() {
  // a type to encompass our dynamically added properties
  type Proc = (NodeJS.Process & { type?: string; versions?: { electron?: unknown } }) | false

  // Renderer process
  const renderer: Proc = typeof window !== 'undefined' && typeof window.process === 'object' ? window.process : false
  if (renderer && renderer.type === 'renderer') return true

  // Main process
  const main: Proc = typeof process !== 'undefined' && typeof process.versions === 'object' ? process : false
  if (main && !!main.versions.electron) return true

  // Detect the user agent when the `nodeIntegration` option is set to false
  return typeof navigator === 'object' && navigator.userAgent.indexOf('Electron') >= 0
}
