import './Settings.scss'

import React, { useContext, useState } from 'react'

import { AccountSettings, TeamSettings } from '../'
import AccountIcon from '../../../assets/icons/object-account_darkGray2.svg'
import TeamIcon from '../../../assets/icons/object-team_darkGray2.svg'
import CurrentTeamContext from '../../../contexts/CurrentTeamContext'
import UserContext from '../../../contexts/UserContext'
import { Card, Icon, ListItem, ListLabel, Paragraph, UnstyledButton } from '../../atoms'

interface IProps {
  onCancelClick: () => void
}

const Settings = ({ onCancelClick }: IProps) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const currentTeamContext = useContext(CurrentTeamContext)
  const user = useContext(UserContext)

  const userIsAdmin = currentTeamContext.currentTeam?.memberships?.some(
    membership => membership.userId === user?.uid && membership.admin,
  )

  const contents = [<AccountSettings onCancelClick={onCancelClick} />, <TeamSettings onCancelClick={onCancelClick} />]

  return (
    <div className="settings-container">
      <Card className="settings-categories">
        <Card.Body padding="list" size="tiny">
          <ListLabel>Settings</ListLabel>

          <ul>
            <li>
              <UnstyledButton displayBlock={true} fullWidth={true} onClick={() => setSelectedOptionIndex(0)}>
                <ListItem selected={selectedOptionIndex === 0} tag="div" size="medium">
                  <Icon alt="Account icon" src={AccountIcon} />
                  <Paragraph bold={selectedOptionIndex === 0}>Account</Paragraph>
                </ListItem>
              </UnstyledButton>
            </li>

            {userIsAdmin ? (
              <li>
                <UnstyledButton displayBlock={true} fullWidth={true} onClick={() => setSelectedOptionIndex(1)}>
                  <ListItem selected={selectedOptionIndex === 1} tag="div" size="medium">
                    <Icon alt="Team icon" src={TeamIcon} />
                    <Paragraph bold={selectedOptionIndex === 1}>Team</Paragraph>
                  </ListItem>
                </UnstyledButton>
              </li>
            ) : null}
          </ul>
        </Card.Body>
      </Card>

      <Card className="settings-content">{contents[selectedOptionIndex]}</Card>
    </div>
  )
}

export default Settings
