import firebase from 'firebase/app'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NewProject } from '../'
import ProjectSvg from '../../../assets/icons/sidebar-project_darkGray3.svg'
import CurrentTeamContext from '../../../contexts/CurrentTeamContext'
import { FirestoreContext } from '../../../firebase'
import { IProject } from '../../../types/projects'
import { Modal, SidebarItem, SidebarSectionHeader } from '../../molecules'
import './SidebarProjects.scss'

interface IProps {
  selectedProjectId: string
  projects: IProject[]
}

const SidebarProjects = (props: IProps) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const currentTeamContext = useContext(CurrentTeamContext)
  const firestore = useContext(FirestoreContext)
  const history = useHistory()

  const newProjectHandler = () => {
    setNewProjectModalOpen(true)
  }

  const projectClickHandler = async (projectId: string) => {
    // TODO: Figure out loading state for switching projects

    if (!currentTeamContext.currentTeam) {
      console.log('No current team set')
      toast.error('Something went wrong')
      return
    }

    try {
      const projectSnapshot = await firestore
        .collection('teams')
        .doc(currentTeamContext.currentTeam.id)
        .collection('projects')
        .doc(projectId)
        .get()

      const querySnapshot = await firestore.collection('canvases').where('project', '==', projectSnapshot.ref).get()

      if (querySnapshot.size === 0) {
        console.log('Project has no canvases')
        toast.error('This project has no canvases')
        return
      }

      history.push(`/canvas/${querySnapshot.docs[0].id}`)
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong')
    }
  }

  const onRenameSubmit = (projectId: string) => async (name: string) => {
    if (!currentTeamContext.currentTeam) {
      console.log('No current team set')
      toast.error('Something went wrong')
      return
    }

    await firestore
      .collection('teams')
      .doc(currentTeamContext.currentTeam.id)
      .collection('projects')
      .doc(projectId)
      .update({
        name,
      })
  }

  const deleteAction = async (projectId: string, currentProjectId: string) => {
    // TODO: remove this restriction if we allow deletion of current project
    if (projectId === currentProjectId) {
      throw new Error("Can't delete your current project")
    }

    if (!currentTeamContext.currentTeam) {
      console.log('No current team set')
      toast.error('Something went wrong')
      throw new Error('No current team set')
    }

    await firebase.functions().httpsCallable('deleteProject')({
      teamId: currentTeamContext.currentTeam.id,
      projectId,
    })

    // TODO: redirect if you delete your current project
    // currently going to restrict this
    // if (projectId === currentProjectId) {
    // TODO: redirect to next project's first canvas
    // history.push(`/canvas/${nextCanvasId}`)
    // }
  }

  return (
    <div className="sidebar-projects">
      <SidebarSectionHeader title="Projects" onAddClick={newProjectHandler} />

      <ul>
        {props.projects.map(project => (
          <SidebarItem
            key={project.id}
            itemName={project.name}
            selected={props.selectedProjectId === project.id}
            iconSrc={ProjectSvg}
            iconAlt="Project Icon"
            onRenameSubmit={onRenameSubmit(project.id)}
            deleteAction={() => deleteAction(project.id, props.selectedProjectId)}
            deleteDisabled={props.projects.length === 1 || project.id === props.selectedProjectId}
            onItemClick={() => projectClickHandler(project.id)}
          />
        ))}
      </ul>

      <Modal isOpen={newProjectModalOpen} onRequestClose={() => setNewProjectModalOpen(false)}>
        <NewProject onProjectCreated={() => setNewProjectModalOpen(false)} />
      </Modal>
    </div>
  )
}

export default SidebarProjects
