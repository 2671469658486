import { History } from 'history'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import React, { useCallback, useEffect } from 'react'

import { ZOOM_MAX, ZOOM_MIN } from '../constants/position'
import { IContainer, IContainerInstance } from '../types/containers'
import { KonvaElement, KonvaMouseEvt } from '../types/konva'
import { AreaCoords, MossMediaSelectionTuple } from '../types/moss'
import { DragContainerPreviews, IPreview, IPreviewInstance } from '../types/previews'
import { clamp, roundToDecimal } from './util'

// START - UTILS - START
export const getZoomedAndScrolledPosition = (
  eventCoords: [number, number],
  canvasShiftCoords: [number, number],
  zoom: number,
) => eventCoords.map((eCoord, i) => Math.round((eCoord - canvasShiftCoords[i]) / zoom))

export const isWithinSelectedArea = (position: [number, number], selectedAreaCoords?: AreaCoords) => {
  if (!selectedAreaCoords) return false
  const [x, y] = position
  const [tlX, tlY, brX, brY] = selectedAreaCoords
  if (x >= tlX && x <= brX && y >= tlY && y <= brY) {
    return true
  }
  return false
}

export const updateUrlLocation = debounce((history: History, x, y, scale: number) => {
  const {
    location: { pathname },
    replace,
  } = history
  const lastChar = pathname[pathname.length - 1]

  replace(`${pathname}${lastChar === '/' ? '' : '/'}?p=${roundToDecimal(x, 2)},${roundToDecimal(y, 2)},${scale}`)
}, 100)
// END - UTILS - END

// START - SCROLL, ZOOM, WHEEL - START
// A lower throttle count decreases performance but increases smoothness
const wheelThrottle = 15 //ms, 16ms is ~once/frame at 60fps
const zoomFriction = 110

const scrollOrZoom = throttle(
  (
    stageRef: React.RefObject<KonvaElement>,
    setZoom: (zoom: number) => void,
    scrollingLocked: boolean,
    history: History,
    e: any,
  ) => {
    if (!scrollingLocked) {
      requestAnimationFrame(() => {
        const oldScale = stageRef?.current?.scaleX()
        if (e.evt.ctrlKey) {
          // Zooming
          const pointer = stageRef?.current?.getPointerPosition()
          const [mouseX, mouseY] = getZoomedAndScrolledPosition(
            [pointer.x, pointer.y],
            [stageRef?.current?.x(), stageRef?.current?.y()],
            oldScale,
          )
          const mousePointTo = {
            x: mouseX,
            y: mouseY,
          }

          const dynamicScale = 1 + Math.abs(e.evt.deltaY) / zoomFriction
          let newScale = e.evt.deltaY <= 0 ? oldScale * dynamicScale : oldScale / dynamicScale
          // Round to 2 decimal places, limit to between zoom min and max
          newScale = clamp(roundToDecimal(newScale, 2), ZOOM_MIN, ZOOM_MAX)

          if (oldScale !== newScale || e.overrides?.zoom) {
            const appliedZoom = e.overrides?.zoom || newScale
            stageRef?.current?.scale({ x: appliedZoom, y: appliedZoom })

            const newPos = {
              x: pointer.x - mousePointTo.x * appliedZoom,
              y: pointer.y - mousePointTo.y * appliedZoom,
            }
            stageRef?.current?.position(newPos)
            stageRef?.current?.batchDraw()
            setZoom(appliedZoom)
            updateUrlLocation(history, newPos.x, newPos.y, appliedZoom)
          }
        } else {
          // Panning
          const newPos = {
            x: stageRef?.current?.x() + e.evt.wheelDeltaX,
            y: stageRef?.current?.y() + e.evt.wheelDeltaY,
          }
          stageRef?.current?.position(newPos)
          stageRef?.current?.batchDraw()
          updateUrlLocation(history, newPos.x, newPos.y, oldScale)
        }
      })
    }
  },
  wheelThrottle,
)

export const createOnStageScroll = (
  stageRef: React.RefObject<KonvaElement>,
  setZoom: (zoom: number) => void,
  scrollingLocked: boolean,
  history: History,
) => (e: any) => {
  /*
    scrollOrZoom is throttled so that the complex canvas positioning and resizing logic
    isn't run on every ms of scrolling/zooming

    However, if we don't prevent the scroll or zoom event default on every event fire
    we end up with the browser handling scroll or zoom events inbetween our throttled
    handling, which leads to some seriously janky scrolling/zooming behavior
  */
  e.evt.preventDefault()
  scrollOrZoom(stageRef, setZoom, scrollingLocked, history, e)
}
// END - SCROLL, WHEEL, ZOOM - END

const updateDragSelectRect = (dragSelectRectRef: any, dragSelectCoordsRef: any) => {
  const node = dragSelectRectRef.current
  node.setAttrs({
    visible: dragSelectCoordsRef.current.visible,
    x: Math.min(dragSelectCoordsRef.current.x1, dragSelectCoordsRef.current.x2),
    y: Math.min(dragSelectCoordsRef.current.y1, dragSelectCoordsRef.current.y2),
    width: Math.abs(dragSelectCoordsRef.current.x1 - dragSelectCoordsRef.current.x2),
    height: Math.abs(dragSelectCoordsRef.current.y1 - dragSelectCoordsRef.current.y2),
  })
  node.getLayer().batchDraw()
}

// START - MOUSE & CLICK - START
export const createOnStageMouseDown = ({
  isMouseDownRef,
  setStagePanStartCoordinates,
  isHoldingSpacebarRef,
  setCursor,
  stageRef,
  setSequencingHack,
  zoom,
  isResizingRef,
  setActiveContainerInstance,
  updateSelectedObjects,
  dragSelectRectRef,
  dragSelectCoordsRef,
}: {
  isMouseDownRef: React.MutableRefObject<boolean>
  setStagePanStartCoordinates: (coords: [number, number]) => void
  isHoldingSpacebarRef: React.MutableRefObject<boolean>
  setCursor: (cursor: string) => void
  stageRef: React.RefObject<KonvaElement> // Konva Stage
  setSequencingHack: (val: boolean) => void
  zoom: number
  isResizingRef: React.MutableRefObject<boolean>
  setActiveContainerInstance: (instance: null) => void
  updateSelectedObjects: (shouldCombine: boolean, incomingMediaTuple: MossMediaSelectionTuple[]) => void
  dragSelectRectRef: any
  dragSelectCoordsRef: any
}) => (e: KonvaMouseEvt) => {
  const { x, y } = e.evt
  isMouseDownRef.current = true
  if (isHoldingSpacebarRef.current) {
    setStagePanStartCoordinates([x, y])
    setCursor('grabbing')
  } else if (e.target.attrs.id === 'stage' || e.target.attrs.id === 'selectionArea') {
    // clicked on empty space, so start new selection rect
    if (!e.evt.shiftKey) {
      // clear selected items if not holding shift
      updateSelectedObjects(false, emptySelected)
      setActiveContainerInstance(null)
    }
    const { x: offsetX, y: offsetY } = stageRef.current.position()
    const [canvasSpaceX, canvasSpaceY] = getZoomedAndScrolledPosition([x, y], [offsetX, offsetY], zoom)
    dragSelectCoordsRef.current.visible = true
    dragSelectCoordsRef.current.x1 = canvasSpaceX
    dragSelectCoordsRef.current.y1 = canvasSpaceY
    dragSelectCoordsRef.current.x2 = canvasSpaceX
    dragSelectCoordsRef.current.y2 = canvasSpaceY
    updateDragSelectRect(dragSelectRectRef, dragSelectCoordsRef)
    // TODO this should no longer be necessary, but there is some complicated event order interaction with
    // editing Notes which breaks if this is removed
    setSequencingHack(true)
  } else if (e.target.attrs.name?.includes('_anchor')) {
    isResizingRef.current = true
  }
}

const rectsIntersect = (
  r1: { x: number; y: number; width: number; height: number },
  r2: { x: number; y: number; width: number; height: number },
) => {
  return !(r2.x > r1.x + r1.width || r2.x + r2.width < r1.x || r2.y > r1.y + r1.height || r2.y + r2.height < r1.y)
}

const emptySelected: [] = []
export const createOnStageMouseUp = ({
  stagePanStartCoordinates,
  setStagePanStartCoordinates,
  isHoldingSpacebarRef,
  setCursor,
  dragContainerPreviews,
  completeContainerPreviewsDrag,
  isMouseDownRef,
  stageRef,
  updateSelectedObjects,
  previewsData,
  containersData,
  setActiveContainerInstance,
  zoom,
  isResizingRef,
  dragSelectRectRef,
  dragSelectCoordsRef,
  getInstanceRef,
}: {
  stagePanStartCoordinates: [number, number] | null
  setStagePanStartCoordinates: (coords: null) => void
  isMouseDownRef: React.MutableRefObject<boolean>
  isHoldingSpacebarRef: React.MutableRefObject<boolean>
  setCursor: (cursor: string) => void
  dragContainerPreviews: DragContainerPreviews
  completeContainerPreviewsDrag: (e: KonvaMouseEvt) => void
  stageRef: React.RefObject<KonvaElement>
  updateSelectedObjects: (shouldCombine: boolean, incomingMediaTuple: MossMediaSelectionTuple[]) => void
  previewsData: { [key: string]: IPreview }
  containersData: { [key: string]: IContainer }
  setActiveContainerInstance: (instance: null) => void
  zoom: number
  isResizingRef: React.MutableRefObject<boolean>
  dragSelectRectRef: any
  dragSelectCoordsRef: any
  getInstanceRef: (instanceId: string) => React.MutableRefObject<any> | undefined
}) => (e: KonvaMouseEvt) => {
  isMouseDownRef.current = false
  // clear panning coordinates
  if (stagePanStartCoordinates) setStagePanStartCoordinates(null)

  // reset cursor
  isHoldingSpacebarRef.current ? setCursor('grab') : setCursor('default')

  // finish container preview dragging
  if (dragContainerPreviews.previews.length) {
    completeContainerPreviewsDrag(e)
    updateSelectedObjects(false, emptySelected)
  }

  if (dragSelectCoordsRef.current.visible) {
    // mouseup during drag select, so select all objects that intersect the selection rect

    // Get the selection rect in canvas space (adjusted by pan/zoom)
    const selectionRect = dragSelectRectRef.current.getClientRect()
    const { x: stageOffsetX, y: stageOffsetY } = stageRef.current.position()
    const [canvasSpaceSelectionRectX, canvasSpaceSelectionRectY] = getZoomedAndScrolledPosition(
      [selectionRect.x, selectionRect.y],
      [stageOffsetX, stageOffsetY],
      zoom,
    )
    const canvasSpaceSelectionRect = {
      x: canvasSpaceSelectionRectX,
      y: canvasSpaceSelectionRectY,
      width: selectionRect.width / zoom,
      height: selectionRect.height / zoom,
    }

    const newlySelectedObjects: MossMediaSelectionTuple[] = []
    // determine which preview instances intersect the drag select
    Object.keys(previewsData).forEach((key: string) => {
      const preview = previewsData[key]
      preview?.instances?.forEach((instance: IPreviewInstance, idx: number) => {
        let canvasSpaceObjectRect
        if (preview.mime_type === 'mossText') {
          const instanceRef = getInstanceRef(instance.instanceId)
          const { width, height } = instanceRef?.current.getClientRect()
          // getClientRect gives us the dimensions in screen space so we unzoom to get it in canvas space
          canvasSpaceObjectRect = {
            x: instance.X,
            y: instance.Y,
            width: width / zoom,
            height: height / zoom,
          }
        } else {
          canvasSpaceObjectRect = {
            x: instance.X,
            y: instance.Y,
            width: preview.dimensions[0] * instance.scale,
            height: preview.dimensions[1] * instance.scale,
          }
        }
        if (rectsIntersect(canvasSpaceSelectionRect, canvasSpaceObjectRect)) {
          const instanceRef = getInstanceRef(instance.instanceId)
          const previewTuple: MossMediaSelectionTuple = [preview, instance, instanceRef?.current]
          newlySelectedObjects.push(previewTuple)
        }
      })
    })
    // determine which container instances intersect the drag select
    Object.keys(containersData).forEach((key: string) => {
      const container = containersData[key]
      container?.instances?.forEach((instance: IContainerInstance, idx: number) => {
        const canvasSpaceObjectRect = {
          x: instance.X,
          y: instance.Y,
          width: container.width,
          height: container.height,
        }
        if (rectsIntersect(canvasSpaceSelectionRect, canvasSpaceObjectRect)) {
          const instanceRef = getInstanceRef(instance.instanceId)
          const previewTuple: MossMediaSelectionTuple = [container, instance, instanceRef?.current]
          newlySelectedObjects.push(previewTuple)
        }
      })
    })

    updateSelectedObjects(true, newlySelectedObjects)
    dragSelectCoordsRef.current.visible = false
    updateDragSelectRect(dragSelectRectRef, dragSelectCoordsRef)
  }
  isResizingRef.current = false
}

export const createOnStageMouseMove = ({
  isHoldingSpacebarRef,
  stagePanStartCoordinates,
  stageRef,
  dragContainerPreviews,
  setDragContainerPreviewsOffset,
  stagePositionStartPanCoordinatesRef,
  zoom,
  isMouseDownRef,
  dragSelectRectRef,
  dragSelectCoordsRef,
  history,
}: {
  isHoldingSpacebarRef: React.MutableRefObject<boolean>
  stageRef: React.RefObject<KonvaElement>
  dragContainerPreviews: any
  setDragContainerPreviewsOffset: (coords: [number, number] | null) => void
  // TODO type these correctly
  // stagePanStartCoordinates: [number, number] | null
  // stagePositionStartPanCoordinatesRef: React.RefObject<[number, number]>
  stagePanStartCoordinates: any
  stagePositionStartPanCoordinatesRef: React.RefObject<any>
  zoom: number
  isMouseDownRef: React.MutableRefObject<boolean>
  dragSelectRectRef: any
  dragSelectCoordsRef: any
  history: History
}) => (e: KonvaMouseEvt) => {
  const isDraggingStage =
    isHoldingSpacebarRef.current && stagePanStartCoordinates && stagePositionStartPanCoordinatesRef.current
  const isDraggingContainerPreview = dragContainerPreviews.previews.length

  if (isDraggingContainerPreview || isDraggingStage) {
    // Get the abs evt position & current stage position, calculate evt position in Canvas (object) space
    const { x, y }: { x: number; y: number } = e.evt
    const { x: stageOffsetX, y: stageOffsetY } = stageRef.current.position()
    const [canvasSpaceMouseX, canvasSpaceMouseY] = getZoomedAndScrolledPosition(
      [x, y],
      [stageOffsetX, stageOffsetY],
      zoom,
    )

    if (isDraggingStage) {
      // If moving the stage get the initial stage pan & pan event coordinates...
      const [stageInitX, stageInitY] = stagePositionStartPanCoordinatesRef.current
      const [initX, initY] = stagePanStartCoordinates
      // ...and use those to create a new x and y position for the canvas
      const newX = Math.round(stageInitX - (initX - x))
      const newY = Math.round(stageInitY - (initY - y))

      stageRef?.current?.position({ x: newX, y: newY })
      stageRef?.current?.batchDraw()
      updateUrlLocation(history, newX, newY, zoom)
    } else if (isDraggingContainerPreview) {
      // If dragging a container preview calculate the proper offset coord position
      // then set those coords as the new preview coords
      const offsetX = dragContainerPreviews.instanceX + canvasSpaceMouseX - dragContainerPreviews.startX
      const offsetY = dragContainerPreviews.instanceY + canvasSpaceMouseY - dragContainerPreviews.startY

      setDragContainerPreviewsOffset([offsetX, offsetY])
    }
  } else if (dragSelectCoordsRef.current.visible) {
    // Get the abs evt position & current stage position, calculate evt position in Canvas (object) space
    const { x, y }: { x: number; y: number } = e.evt
    const { x: stageOffsetX, y: stageOffsetY } = stageRef.current.position()
    const [canvasSpaceMouseX, canvasSpaceMouseY] = getZoomedAndScrolledPosition(
      [x, y],
      [stageOffsetX, stageOffsetY],
      zoom,
    )

    dragSelectCoordsRef.current.x2 = canvasSpaceMouseX
    dragSelectCoordsRef.current.y2 = canvasSpaceMouseY
    updateDragSelectRect(dragSelectRectRef, dragSelectCoordsRef)
  }
}

// END - MOUSE & CLICK - END

//---------------------------
// START - KEYBOARD - START
export const useAddKeyboardListener = ({
  stageRef,
  isHoldingSpacebarRef,
  isMouseDownRef,
  deleteSelected,
  pasteSelected,
  onStageScroll,
}: {
  stageRef: React.RefObject<KonvaElement>
  isHoldingSpacebarRef: React.MutableRefObject<boolean>
  isMouseDownRef: React.MutableRefObject<boolean>
  deleteSelected: () => void
  pasteSelected: () => void
  onStageScroll: (e: any) => void
}) => {
  const handleKeyDown = useCallback(
    createHandleKeyDown({
      isHoldingSpacebarRef,
      stageRef,
      isMouseDownRef,
      deleteSelected,
      pasteSelected,
      onStageScroll,
    }),
    [isMouseDownRef, pasteSelected, deleteSelected, onStageScroll, stageRef],
  )
  const handleKeyUp = useCallback(createHandleKeyUp(isHoldingSpacebarRef, stageRef), [stageRef])

  useEffect(() => {
    if (stageRef.current) {
      const stageRefHTMLElem = stageRef.current.container()
      stageRefHTMLElem.tabIndex = 1
      // TODO: this interferes with autofocusing the name input for a new canvas
      // just commenting out for now to let that feature work
      // stageRefHTMLElem.focus()
      stageRefHTMLElem?.addEventListener('keydown', handleKeyDown)
      stageRefHTMLElem?.addEventListener('keyup', handleKeyUp)
    }
  }, [stageRef.current, stageRef, handleKeyDown, handleKeyUp])
}

const createHandleKeyDown = ({
  isHoldingSpacebarRef,
  stageRef,
  isMouseDownRef,
  deleteSelected,
  pasteSelected,
  onStageScroll,
}: {
  stageRef: React.RefObject<KonvaElement>
  isHoldingSpacebarRef: React.MutableRefObject<boolean>
  isMouseDownRef: React.MutableRefObject<boolean>
  deleteSelected: () => void
  pasteSelected: () => void
  onStageScroll: (e: any) => void
}) => (e: KeyboardEvent) => {
  if (e.repeat) return
  switch (e.code.toLowerCase()) {
    case 'space':
      isHoldingSpacebarRef.current = true
      if (isMouseDownRef.current) {
        stageRef.current.container().style.cursor = 'grabbing'
      } else {
        stageRef.current.container().style.cursor = 'grab'
      }
      break
    case 'backspace':
    case 'delete':
      deleteSelected()
      break
    case 'keyv':
      pasteSelected()
      break
    case 'digit0':
      onStageScroll({
        evt: {
          ctrlKey: true,
          preventDefault: () => {},
        },
        overrides: {
          zoom: 1,
        },
      })
      break
    // Don't assign anything to 'c'
    // Many users will try to use c to copy before pasting
    // Which, while unnecessary, is a common enough pattern that
    // we don't want to interrupt it
    case 'keyc':
    default:
      break
  }
}

const createHandleKeyUp = (
  isHoldingSpacebarRef: React.MutableRefObject<boolean>,
  stageRef: React.RefObject<KonvaElement>,
) => (e: KeyboardEvent) => {
  if (e.repeat) return
  if (e.code.toLowerCase() === 'space') {
    isHoldingSpacebarRef.current = false
    stageRef.current.container().style.cursor = 'default'
  }
}
// END - KEYBOARD - END
