import firebase from 'firebase/app'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import { FIREBASE_EMAIL_LOCAL_STORAGE_KEY } from '../../constants/localStorage'
import UserContext from '../../contexts/UserContext'
import { FIREBASE_ACTION_CODE_SETTINGS, firestore } from '../../firebase'
import { Button, Input, Paragraph, TextLink, UnstyledButton } from '../../ui/atoms'
import { LoadingLayout } from '../../ui/templates'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'

const FinishSignIn = () => {
  const [linkSent, setLinkSent] = useState<boolean>(false)
  const [expiredLinkEmail, setExpiredLinkEmail] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [sendingLink, setSendingLink] = useState(false)

  const history = useHistory()
  const user = useContext(UserContext)

  const redirectUser = async (uid: string) => {
    const userDoc = await firestore.collection('users').doc(uid).get()
    const userData = userDoc.data()

    if (!userData) {
      toast.error('Something went wrong, user data does not exist')
      history.push('/sign-in')
      return
    }

    if (userData.currentCanvasId) {
      history.push(`/canvas/${userData.currentCanvasId}`)
      return
    }

    if (userData.onboardingInstallSyncerDone) {
      history.push('/')
      return
    }

    history.push('/onboarding-name')
  }

  const signInUser = async (email: string, emailLink: string) => {
    try {
      const signInResult = await firebase.auth().signInWithEmailLink(email, emailLink)

      window.localStorage.removeItem(FIREBASE_EMAIL_LOCAL_STORAGE_KEY)

      if (!signInResult.user) {
        toast.error('Something went wrong. Could not find that user.')
        history.push('/sign-in')
        return
      }

      if (signInResult.additionalUserInfo?.isNewUser) {
        history.push('/onboarding-name')
      }

      await redirectUser(signInResult.user.uid)
    } catch (error) {
      if (error.code === 'auth/expired-action-code' || error.code === 'auth/invalid-action-code') {
        setExpiredLinkEmail(email)
      } else {
        console.error(error)
        toast.error(error.message)
        history.push('/sign-in')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // can't directly redirect from sign in callback
    // b/c firebase async updates through onAuthStateChanged
    // need to wait until user exists before redirecting
    // avoids race condition
    if (user) {
      redirectUser(user.uid)
      return
    }

    if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
      history.push('/')
      return
    }

    // Auto login user if they requested sign in on same device
    const storedEmail = window.localStorage.getItem(FIREBASE_EMAIL_LOCAL_STORAGE_KEY)

    if (!storedEmail) {
      setLoading(false)
      return
    }

    signInUser(storedEmail, window.location.href)
  }, [user, history])

  if (loading) {
    return <LoadingLayout />
  }

  if (linkSent) {
    return (
      <OnboardingLayout
        mainHeading="We just emailed you."
        subHeading="Follow the link we sent to log into Moss."
        footer={
          <Paragraph>
            Don't see the email?{' '}
            <UnstyledButton onClick={() => setLinkSent(false)}>
              <TextLink>Try again</TextLink>
            </UnstyledButton>
            {'.'}
          </Paragraph>
        }
      />
    )
  }

  if (expiredLinkEmail) {
    return (
      <OnboardingLayout
        mainHeading="Your link expired"
        footer={
          <Paragraph>
            Not you? <Link to="/sign-in">Sign in with a different account</Link>
            {'.'}
          </Paragraph>
        }
      >
        <div>
          <Button
            fullWidth={false}
            variant={'gray-1'}
            onClick={async () => {
              try {
                setSendingLink(true)
                window.localStorage.setItem(FIREBASE_EMAIL_LOCAL_STORAGE_KEY, expiredLinkEmail)
                await firebase.auth().sendSignInLinkToEmail(expiredLinkEmail, FIREBASE_ACTION_CODE_SETTINGS)
                setLinkSent(true)
              } catch (error) {
                console.error(error)
                toast.error(error.message)
                history.push('/sign-in')
              } finally {
                setSendingLink(false)
              }
            }}
          >
            {sendingLink ? 'Sending' : `Send a new link to ${expiredLinkEmail}`}
          </Button>
        </div>
      </OnboardingLayout>
    )
  }

  return (
    <OnboardingLayout
      mainHeading="Welcome!"
      subHeading="You’ve been invited to join the Moss Alpha. Let’s get you set up."
      footer={
        <Paragraph>
          Don't have an account?{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://mossworkspace.com/">
            <TextLink>Request Alpha access</TextLink>
          </a>
          {'.'}
        </Paragraph>
      }
    >
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={object({
          email: string().email().required('Required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await signInUser(values.email, window.location.href)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, handleChange, handleBlur, values }) => (
          <Form>
            <div className="onboarding-layout-hero">
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Confirm your email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                fullWidth={true}
                large={true}
                validation={true}
              />

              <Button type="submit" variant="blue" disabled={isSubmitting} fullWidth={true}>
                {isSubmitting ? 'Confirming' : 'Get Started'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  )
}

export default FinishSignIn
