import './SidebarItem.scss'

import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import EditIcon from '../../../assets/icons/action-edit_darkGray3.svg'
import EllipsisIcon from '../../../assets/icons/menu-ellipsis_darkGray2.svg'
import DeleteIcon from '../../../assets/icons/object-trash_darkGray3.svg'
import DeleteIconDisabled from '../../../assets/icons/object-trash_darkGray1.svg'

import { Button, Card, Icon, Input, ListItem, Paragraph, UnstyledButton } from '../../atoms'

interface ICommonProps {
  itemName: string
  selected: boolean
  iconSrc: string
  iconAlt: string
  onRenameSubmit: (name: string) => Promise<void>
  deleteAction: () => Promise<void>
  deleteDisabled: boolean
}

type ItemLinkProps = { linkUrl?: never; onItemClick: () => void } | { linkUrl: string; onItemClick?: never }

type Props = ICommonProps & ItemLinkProps

interface IPopoverItemButtonProps {
  text: string
  icon: string
  onClick: () => void
  disabled?: boolean
}

const PopoverItemButton = ({ onClick, text, icon, disabled }: IPopoverItemButtonProps) => {
  return (
    <UnstyledButton onClick={onClick} fullWidth={true} textAlignLeft={true} noHover={disabled ? true : false}>
      <ListItem size="medium" tag="div" disabled={disabled ? true : false}>
        <Icon alt={`${text} icon`} src={icon} />
        <Paragraph>{text}</Paragraph>
      </ListItem>
    </UnstyledButton>
  )
}

const SidebarItem = ({
  itemName,
  selected,
  iconSrc,
  iconAlt,
  onRenameSubmit,
  deleteAction,
  deleteDisabled,
  linkUrl,
  onItemClick,
}: Props) => {
  const [renameOpen, setRenameOpen] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onDeleteButtonClick = () => {
    setDeleting(true)

    deleteAction().catch(err => {
      console.log(err)
      toast.error(`Sorry, can't delete this`)
      setDeleting(false)
    })
  }

  const renderRenameForm = (name: string) => {
    return (
      <Formik
        initialValues={{ name }}
        validationSchema={object({
          name: string().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onRenameSubmit(values.name)
            .catch(err => {
              console.log(err)
              toast.error('An error occurred trying while renaming')
              setSubmitting(false)
            })
            .finally(() => {
              setRenameOpen(false)
            })
        }}
      >
        {({ submitForm, handleBlur, handleChange, values, isSubmitting }) => (
          <Form className="sidebar-item__rename-form">
            <Input
              id="name"
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={e => {
                handleBlur(e)
                submitForm()
              }}
              value={values.name}
              validation={true}
              autoFocus={true}
              onFocus={e => e.target.select()}
            />
            {/* TODO: Figure out proper UI for this */}
            {/* {isSubmitting ? <Paragraph>Updating</Paragraph> : null} */}
          </Form>
        )}
      </Formik>
    )
  }

  const renderItemName = (name: string, selected: boolean) => {
    if (linkUrl) {
      return (
        <Link to={linkUrl} className="sidebar-item__link">
          <Paragraph bold={selected} className="sidebar-item__title">
            {name}
          </Paragraph>
        </Link>
      )
    }

    return (
      <UnstyledButton onClick={onItemClick} fullWidth={true} textAlignLeft={true} displayBlock={true}>
        <Paragraph bold={selected}>{name}</Paragraph>
      </UnstyledButton>
    )
  }

  return (
    <ListItem selected={selected} className="sidebar-item" size="small">
      <Icon alt={iconAlt} src={iconSrc} />

      {renameOpen ? renderRenameForm(itemName) : renderItemName(itemName, selected)}

      <Popover
        isOpen={popoverOpen}
        onClickOutside={() => setPopoverOpen(false)}
        positions={['bottom']}
        align={'start'}
        padding={5}
        content={
          <Card>
            <Card.Body size="tiny" padding="list">
              <ul>
                <li>
                  <PopoverItemButton onClick={() => setRenameOpen(true)} text="Rename" icon={EditIcon} />
                </li>

                <li>
                  <PopoverItemButton
                    onClick={onDeleteButtonClick}
                    text={deleting ? 'Deleting' : 'Delete'}
                    icon={deleteDisabled ? DeleteIconDisabled : DeleteIcon}
                    disabled={deleteDisabled || deleting}
                  />
                </li>
              </ul>
            </Card.Body>
          </Card>
        }
      >
        <Button
          className="sidebar-item__options-button"
          onClick={() => setPopoverOpen(!popoverOpen)}
          size="tiny"
          variant={selected ? 'gray-2' : 'gray-1'}
          borderless={true}
          tooltip={popoverOpen ? '' : 'Rename, delete'}
        >
          <Icon src={EllipsisIcon} alt="Ellipsis icon" />
        </Button>
      </Popover>
    </ListItem>
  )
}

export default SidebarItem
