import './Tooltip.scss'

import React from 'react'

interface IProps {
  children: any
  className?: string
}

const Tooltip = ({ children, className }: IProps) => {
  return <div className={`tooltip ${className || ''}`}>{children}</div>
}

export default Tooltip
