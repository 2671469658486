import { Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import { TEAM_LOGO_SIZE_LIMIT_BYTES } from '../../../constants/moss'
import CurrentTeamContext from '../../../contexts/CurrentTeamContext'
import { firestore } from '../../../firebase'
import { inviteUsersToTeam, uploadImage } from '../../../logic/firebase'
import { fileSizeLimitTest } from '../../../logic/forms'
import { Button, Card, Divider, H2, ImageInput, Input, ListItem, Paragraph, SmallText } from '../../atoms'

interface IProps {
  onCancelClick: () => void
}

interface ITeamFormValues {
  name: string
  logoFile: File | null
  logoUrl: string
}

const teamFormValidationSchema = object({
  name: string().required('Required'),
  logoFile: fileSizeLimitTest(TEAM_LOGO_SIZE_LIMIT_BYTES),
})

const TeamSettings = ({ onCancelClick }: IProps) => {
  const currentTeamContext = useContext(CurrentTeamContext)
  const [inviting, setInviting] = useState(false)

  const sendInvite = (email: string, resetForm: () => void) => {
    setInviting(true)

    if (!currentTeamContext.currentTeam?.id) {
      console.log('Missing current team id')
      toast.error('Missing current team information, please refresh the page.')
      setInviting(false)
      return
    }

    return inviteUsersToTeam(currentTeamContext.currentTeam.id, [email])
      .then(newMemberships => {
        currentTeamContext.setCurrentTeam(oldTeam =>
          Object.assign(oldTeam, {
            memberships: oldTeam?.memberships ? [...oldTeam?.memberships, ...newMemberships] : newMemberships,
          }),
        )

        resetForm()
      })
      .catch(err => {
        console.log(err)
        toast.error('Something went wrong')
        setInviting(false)
      })
      .finally(() => {
        setInviting(false)
      })
  }

  // Should not happen, but if somehow currentTeam still null
  if (!currentTeamContext || !currentTeamContext.currentTeam)
    return (
      <Card>
        <Card.Body padding="form">
          <H2>...No Team information</H2>
        </Card.Body>
      </Card>
    )

  const initialTeamValues: ITeamFormValues = {
    name: currentTeamContext.currentTeam.name,
    logoFile: null,
    logoUrl: currentTeamContext.currentTeam.logoUrl || '',
  }

  return (
    <div>
      <Card.Body className="settings-content--inner" padding="form" size="xl">
        <SmallText>Team</SmallText>

        <Formik
          initialValues={initialTeamValues}
          validationSchema={teamFormValidationSchema}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            try {
              let newLogoUrl

              if (values.logoFile) {
                newLogoUrl = await uploadImage(values.logoFile)
                setFieldValue('logoUrl', newLogoUrl)
                setFieldValue('logoFile', null, false)
              }

              const newTeamValues = {
                name: values.name,
                logoUrl: newLogoUrl || values.logoUrl,
              }

              await firestore.collection('teams').doc(currentTeamContext.currentTeam?.id).update(newTeamValues)

              currentTeamContext.setCurrentTeam(oldTeam => Object.assign(oldTeam, newTeamValues))

              toast.success('Team Updated!')
            } catch (err) {
              console.log(err)
              toast.error('Something went wrong while updating team settings')
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ handleChange, handleBlur, values, setFieldValue, setFieldTouched, isSubmitting }) => (
            <Form id="team-settings">
              <div className="input-button">
                <ImageInput
                  variant="small"
                  initialImageUrl={values.logoUrl}
                  imageAlt="Team logo"
                  id="logoFile"
                  name="logoFile"
                  validation={true}
                  onChange={event => {
                    if (event.currentTarget.files) {
                      setFieldTouched('logoFile', true)
                      setFieldValue('logoFile', event.currentTarget.files[0])
                    }
                  }}
                />

                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter team name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  fullWidth={true}
                  validation={true}
                />
              </div>
            </Form>
          )}
        </Formik>

        <Divider />

        <SmallText>Teammates</SmallText>

        {currentTeamContext.currentTeam?.memberships ? (
          <ul>
            {currentTeamContext.currentTeam?.memberships.map(member => (
              <ListItem key={member.userId} className="team-member" size="medium">
                <Paragraph>{member.email}</Paragraph>
                <Button size="small" color="white" disabled={true} borderless={true}>
                  {member.admin ? 'Admin' : 'Member'}
                </Button>
              </ListItem>
            ))}
          </ul>
        ) : null}

        <Formik
          initialValues={{ email: '' }}
          validationSchema={object({
            email: string().email().required('Required'),
          })}
          onSubmit={(values, { resetForm }) => {
            if (currentTeamContext.currentTeam?.memberships) {
              let match = currentTeamContext.currentTeam?.memberships.filter(function (member) {
                return member.email === values.email
              })
              if (match.length > 0) {
                toast.error('This person is already a teammate')
              } else {
                sendInvite(values.email, resetForm)
              }
            }
          }}
        >
          {({ handleChange, handleBlur, values }) => (
            <Form>
              <div className="input-button">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter an email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  fullWidth={true}
                  validation={true}
                />

                <Button variant="gray-1" size="medium" type="submit" disabled={inviting}>
                  {inviting ? 'Sending invite' : 'Invite teammate'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>

      <div className="settings-update">
        {/* Removed isSubmitting here, since it's now outside form. Might be nice, but updates seem fast & toast gives feedback */}
        <Button size="medium" variant="blue" type="submit" form="team-settings">
          Update
        </Button>
        <Button variant="gray-1" size="medium" onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default TeamSettings
