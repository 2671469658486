import './OnboardingLayout.scss'

import React from 'react'
import { Box } from 'reflexbox'

import Logo from '../../../assets/logo/moss-icon-small__rgb.svg'
import { H1, H2 } from '../../atoms'

interface IProps {
  mainHeading: string
  subHeading?: string
  children?: any
  footer?: any
}

const OnboardingLayout = ({ mainHeading, subHeading, children, footer }: IProps) => {
  return (
    <div className="onboarding-layout-container">
      <Box mb={[4]} maxWidth={['600px']}>
        <header className="onboarding-layout-header">
          <img src={Logo} alt="Moss logo" className="onboarding-layout-logo" />
          <H1>{mainHeading}</H1>

          {subHeading && (
            <div className="onboarding-layout-subheading">
              <H2>{subHeading} </H2>
            </div>
          )}
        </header>
      </Box>

      <main className="onboarding-layout-content">{children}</main>

      <footer className="onboarding-layout-footer">{footer}</footer>
    </div>
  )
}

export default OnboardingLayout
