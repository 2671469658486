import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import { createContext } from 'react'
import { isElectron } from './electronSupport'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // Electron will not support Google Chrome's Web Push API which is used by Firebase Messaging.
  //   Adding the messagingSenderId to Firebase's config causes a service worker to be
  //   initialized and attempt to use the Push API which causes an error killing the app.
  //   See: https://github.com/electron/electron/issues/6697
  //
  //   There is a project that brings Push API support into Electron:
  //     https://github.com/MatthieuLemoine/electron-push-receiver
  messagingSenderId: !isElectron() ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID : undefined,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firestore = firebase.firestore(firebaseApp)
const firebaseStorageRef = firebase.storage().ref()

export const Timestamp = firebase.firestore.Timestamp

const FirestoreContext = createContext(firestore)

export { firebaseApp, firestore, FirestoreContext, firebaseStorageRef }

export type WithId<T> = T & { id: string }

export interface ICanvas {
  createdAt: firebase.firestore.Timestamp
  shortCode: string
  project: firebase.firestore.DocumentReference
  id: string
  name: string
}

export const FIREBASE_ACTION_CODE_SETTINGS = {
  url: `${window.location.origin}/finish-sign-in`,
  handleCodeInApp: true,
}
