import { Form, Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, string } from 'yup'

import { ONBOARDING_TEAM_LOCAL_STORAGE_KEY } from '../../constants/localStorage'
import { TEAM_LOGO_SIZE_LIMIT_BYTES } from '../../constants/moss'
import UserContext from '../../contexts/UserContext'
import { FirestoreContext, Timestamp } from '../../firebase'
import { createProject, uploadImage } from '../../logic/firebase'
import { fileSizeLimitTest } from '../../logic/forms'
import { Button, ImageInput, Input, Paragraph, TextLink } from '../../ui/atoms'
import OnboardingLayout from '../../ui/templates/OnboardingLayout'

interface ITeamFormValues {
  name: string
  logoFile: File | null
  logoUrl: string
}

const initialTeamValues: ITeamFormValues = {
  name: '',
  logoFile: null,
  logoUrl: '',
}

const validationSchema = object({
  name: string().required('Required'),
  logoFile: fileSizeLimitTest(TEAM_LOGO_SIZE_LIMIT_BYTES),
})

const OnboardingCreateTeam = () => {
  const firestore = useContext(FirestoreContext)
  const user = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    firestore
      .collection('teams')
      .where('creatorId', '==', user?.uid)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.docs.length > 0) {
          history.push('/')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [history, firestore, user])

  return (
    <OnboardingLayout
      mainHeading="Create your team"
      subHeading={`Even if you’re working alone, you’ll still belong to a team. \n You’ll be able to change this info later.`}
      footer={
        <Paragraph>
          New to Moss?{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/About-Teams-13bb71b0d3f84bb99d2718dd7b4e8f43"
          >
            <TextLink>Learn more about teams</TextLink>
          </a>
          {'.'}
        </Paragraph>
      }
    >
      <Formik
        initialValues={initialTeamValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          if (!user) {
            return
          }

          try {
            let newLogoUrl

            if (values.logoFile) {
              newLogoUrl = await uploadImage(values.logoFile)
              setFieldValue('logoUrl', newLogoUrl)
              setFieldValue('logoFile', null, false)
            }

            const teamRef = firestore.collection('teams').doc()
            await teamRef.set({
              createdAt: Timestamp.now(),
              name: values.name,
              creatorId: user.uid,
              logoUrl: newLogoUrl || values.logoUrl,
            })

            const membershipRef = teamRef.collection('memberships').doc(user.uid)
            await membershipRef.set({
              createdAt: Timestamp.now(),
              admin: true,
              userId: user.uid,
              email: user.email,
            })

            await createProject(
              firestore,
              teamRef.id,
              user.uid,
              '👋 Welcome',
              '🌱 Getting started',
              process.env.REACT_APP_WELCOME_CANVAS_ID)

            const userRef = firestore.collection('users').doc(user.uid)
            await userRef.update({
              onboardingTeamId: teamRef.id,
            })

            localStorage.setItem(ONBOARDING_TEAM_LOCAL_STORAGE_KEY, teamRef.id)

            history.push('/onboarding-invite-team')
          } catch (err) {
            console.log(err)
            toast.error(err)
            setSubmitting(false)
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
          <Form>
            <div className="onboarding-layout-hero">
              <div className="input-button">
                <ImageInput
                  initialImageUrl={values.logoUrl}
                  imageAlt="Team logo"
                  id="logoFile"
                  name="logoFile"
                  onChange={event => {
                    if (event.currentTarget.files) {
                      setFieldTouched('logoFile', true)
                      setFieldValue('logoFile', event.currentTarget.files[0])
                    }
                  }}
                  validation={true}
                />
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name your team"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  fullWidth={true}
                  large={true}
                  validation={true}
                />
              </div>

              <Button type="submit" variant="blue" disabled={isSubmitting} fullWidth={true}>
                {isSubmitting ? 'Creating' : 'Create Team'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  )
}

export default OnboardingCreateTeam
