import firebase from 'firebase/app'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import { FIREBASE_ACTION_CODE_SETTINGS } from '../../firebase'

// TODO: Remove this page once sign ups are no longer restricted
const InviteSeedUser = () => {
  const [sending, setSending] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: object({
      email: string().email('Invalid email address').required('Required'),
    }),
    onSubmit: values => {
      setSending(true)

      firebase
        .auth()
        .sendSignInLinkToEmail(values.email, FIREBASE_ACTION_CODE_SETTINGS)
        .then(() => {
          toast(`Invite email was sent to ${values.email}`)
        })
        .catch(error => {
          toast.error(error.message)
        })
        .finally(() => {
          setSending(false)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <label htmlFor="email">Email Address</label>
      <input id="email" name="email" type="email" onChange={formik.handleChange} value={formik.values.email} />

      <button type="submit" disabled={sending}>
        {sending ? 'Sending' : 'Invite User'}
      </button>
    </form>
  )
}

export default InviteSeedUser
