export const darkGray1Fifty = 'rgba(129, 129, 129, 0.5)'

// HEX
////////////////////////////////////////////////////////////////////////////////

// Grays
export const white = '#FCFCFC'

export const lightGray1 = '#F0F0F0'
export const lightGray2 = '#E8E8E8'
export const lightGray3 = '#D9D9D9'
export const lightGray4 = '#CDCDCD'

export const darkGray1 = '#999999'
export const darkGray2 = '#626262'
export const darkGray3 = '#454545'
export const darkGray4 = '#2B2B2B'

export const black = '#121212'

// Blues
export const blue = '#5989F3'
export const midBlue = '#6394FD'
export const lightBlue = '#6D9CFE'

// Reds
export const red = '#F5513B'
export const midRed = '#F5D4CF'
export const lightRed = '#F5EBE7'
