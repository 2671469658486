import React from 'react'
import ReactDOM from 'react-dom'
// NOTE: it is important that the index.scss comes
//   BEFORE ./App as it affects the whole page
import './index.scss'
import * as Sentry from '@sentry/react'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({ dsn: 'https://6669857f80dc4320b47a106269cc388e@o446452.ingest.sentry.io/5424905' })

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={'Moss crashed, please refresh and try again'}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
