import './Divider.scss'

import React from 'react'

interface IProps {
  dark?: boolean
}

export const Divider = ({ dark }: IProps) => {
  return (
    <div className={`divider ${dark ? 'divider--dark' : ''}`}>
      <hr />
    </div>
  )
}

export default Divider
