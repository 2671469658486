import React, { useCallback, useEffect } from 'react'
import { Group } from 'react-konva'
import { toast } from 'react-toastify'

import { firestore } from '../../firebase'
import { IContainer } from '../../types/containers'
import { KonvaElement, KonvaMouseEvt } from '../../types/konva'
import { IPreview, IPreviewInstance } from '../../types/previews'
import { isPreviewCanvasMedia, MossMediaSelectionTuple } from '../../types/moss'

interface Props {
  children: React.ReactNode
  instance: IPreviewInstance | null
  draggable?: boolean
  draggableRef: React.RefObject<KonvaElement>
  selectedObjectsRef: React.RefObject<MossMediaSelectionTuple[]>
  dragEndObjectsRef: React.MutableRefObject<any[]>
  x: number
  y: number
  height?: number
  width?: number
  canvasId: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseDown?: (e: KonvaMouseEvt) => void
  onContextMenu?: (e: KonvaMouseEvt) => void
}

interface PreviewProps extends Props {
  preview: IPreview | null
}

interface ContainerProps extends Props {
  container: IContainer | null
  containerInstanceId: string
}

type DraggableProps = PreviewProps | ContainerProps

const Draggable: React.FC<DraggableProps> = props => {
  const {
    children,
    instance,
    draggable = true,
    draggableRef,
    selectedObjectsRef,
    dragEndObjectsRef,
    x,
    y,
    height,
    width,
    canvasId,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onContextMenu,
  } = props
  const { preview } = props as PreviewProps
  const { container, containerInstanceId } = props as ContainerProps

  useEffect(() => {
    /*
      On resize, the Draggable component gets scaled.
      We then take that scale and apply it to the underlying media
      but if we don't then scale Draggable back to 1 the media will be scaled twice:

      Once from its own scale, and then again by the draggable scale.
    */
    if (draggableRef.current && draggableRef.current?.scaleX() !== 1) {
      draggableRef.current.scale({ x: 1, y: 1 })
    }
  }, [children])

  const onDragEnd = useCallback(async () => {
    const object = preview || container
    if (instance && object) {
      const newInstance = { ...instance }
      const node: KonvaElement = draggableRef.current
      newInstance.X = node.x()
      newInstance.Y = node.y()

      // queue this update for batch write
      // if another instance for the same object is already queued, just modify the same object's instances
      dragEndObjectsRef.current.push([object, newInstance])

      // ***** TODO: verify reliable count of selectedobjects, ie async issues when cleared
      if (selectedObjectsRef.current && dragEndObjectsRef.current.length >= selectedObjectsRef.current.length) {
        try {
          // we need a hash to combine multiple instances of the same object
          // we need to know the object type and id, so use hash of hashes
          const objects: { [objectType: string]: { [objectId: string]: IPreview | IContainer } } = {
            previews: {},
            containers: {},
          }
          dragEndObjectsRef.current.forEach((dragEndObjectData: [IPreview | IContainer, IPreviewInstance]) => {
            let [object, newInstance] = dragEndObjectData
            let objectType, objectId
            if (isPreviewCanvasMedia(object)) {
              objectType = 'previews'
              objectId = object.previewId
            } else {
              objectType = 'containers'
              objectId = object.containerId
            }
            if (objectId in objects[objectType]) object = objects[objectType][objectId]
            else objects[objectType][objectId] = object

            const newInstanceIdx = object.instances.findIndex(
              nInstance => nInstance.instanceId === newInstance.instanceId,
            )
            object.instances[newInstanceIdx] = newInstance
          })

          // we now have a unique list of objects and are ready to update them
          const batch = firestore.batch()
          for (let objectType in objects) {
            for (let objectId in objects[objectType]) {
              const object = objects[objectType][objectId]
              const objectRef = firestore.doc(`canvases/${canvasId}`).collection(objectType).doc(objectId)
              batch.update(objectRef, {
                instances: object.instances,
              })
            }
          }

          // reset the drag object list first in case batch command fails
          dragEndObjectsRef.current = []

          await batch.commit()
        } catch (err) {
          toast.error('Error trying to update cloud data')
        }
      }
    }
  }, [instance, canvasId])

  return (
    <Group
      draggable={draggable}
      x={x}
      y={y}
      containerInstanceId={containerInstanceId}
      height={height}
      width={width}
      ref={draggableRef}
      id={`${
        preview
          ? `preview_${preview.previewId}_${instance?.instanceId}`
          : `container_${container?.containerId}_${instance?.instanceId}`
      }`}
      onClick={(e: KonvaMouseEvt) => {
        e.cancelBubble = true
      }}
      onMouseDown={onMouseDown}
      onDragEnd={onDragEnd}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onContextMenu={onContextMenu}
    >
      {children}
    </Group>
  )
}

export default Draggable
