import firebase from 'firebase/app'
import mixpanel from 'mixpanel-browser'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { NOTE_MIME_TYPE, WEBLINK_MIME_TYPE } from '../constants/mimeTypes'
import { NEW_CANVAS_NAME } from '../constants/moss'
import { firebaseStorageRef, FIREBASE_ACTION_CODE_SETTINGS, firestore, Timestamp } from '../firebase'
import { containersQuery } from '../hooks/containers'
import { previewsQuery } from '../hooks/previews'
import { AddPreviewInstance, Firestore } from '../types/firebase'
import { IMembership } from '../types/teams'

export const addPreviewInstance: AddPreviewInstance = async (
  firestore,
  canvasId,
  previewId,
  objectProps,
  newMediaIdsRef,
) => {
  const instanceId = uuidv4()
  if (newMediaIdsRef) {
    newMediaIdsRef.current = { ...newMediaIdsRef.current, [instanceId]: true }
  }
  await previewsQuery(firestore, canvasId)
    .doc(previewId)
    .update({
      instances: firebase.firestore.FieldValue.arrayUnion({
        instanceId,
        ...objectProps,
      }),
    })
    .catch((error: any) => {
      toast.error('Error adding preview instance: ' + error)
    })
  return instanceId
}

interface CreatePreviewInstanceProps {
  firestore: Firestore
  canvasId: string
  X: number
  Y: number
  text?: string
  fontSize?: string
  scale?: number
  mime_type?: string
  url?: string
  dimensions?: number[]
  previewName?: string
  weblinkUrl?: string
  favIcon?: string
}

export const createPreviewInstance = async ({
  firestore,
  canvasId,
  X,
  Y,
  text,
  fontSize,
  scale,
  mime_type,
  dimensions,
  url,
  previewName,
  weblinkUrl,
  favIcon,
}: CreatePreviewInstanceProps) => {
  const instanceId = uuidv4()
  await previewsQuery(firestore, canvasId)
    .add({
      // some attributes are stored in the preview
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
      ...(previewName && { previewName }),
      ...(mime_type && { mime_type }),
      ...(url && { url }),
      ...(dimensions && { dimensions }),
      ...(weblinkUrl && { weblinkUrl }),
      ...(favIcon && { favIcon }),

      // the rest are stored per instance
      instances: firebase.firestore.FieldValue.arrayUnion({
        X,
        Y,
        instanceId,
        ...(text && { text }),
        ...(fontSize && { fontSize }),
        ...(scale && { scale }),
      }),
    })
    .catch((error: any) => {
      toast.error(`Error creating preview: ${error}`)
      return
    })

  if (mime_type === NOTE_MIME_TYPE) {
    mixpanel.track('Note Creation')
  } else if (mime_type === WEBLINK_MIME_TYPE) {
    mixpanel.track('Weblink Creation')
  }
}

export const updatePreviewInstance = async (
  firestore: Firestore,
  canvasId: string,
  previewId: string,
  instanceId: string,
  updatedInstance: {},
) => {
  const preview: any = await previewsQuery(firestore, canvasId).doc(previewId).get()
  const instances = preview.data().instances
  const instanceIdx = instances.findIndex((e: { instanceId: string }) => e.instanceId === instanceId)
  const newInstancesArray = [...instances]
  newInstancesArray[instanceIdx] = updatedInstance
  await previewsQuery(firestore, canvasId)
    .doc(previewId)
    .update({ instances: newInstancesArray })
    .catch((error: any) => {
      toast.error(`Error updating preview instances: ${error}`)
    })
}

export const deletePreviewInstance = async (
  firestore: Firestore,
  canvasId: string,
  previewId: string,
  instanceId: string,
) => {
  const preview: any = await previewsQuery(firestore, canvasId).doc(previewId).get()
  const newInstancesArray = preview.data().instances.filter((instance: any) => instance.instanceId !== instanceId)
  if (preview.data().instances.length !== newInstancesArray.length) {
    await previewsQuery(firestore, canvasId)
      .doc(previewId)
      .update({ instances: newInstancesArray })
      .catch((error: any) => {
        console.error(error)
        toast.error('Error deleting object')
      })
  }
}

// add a preview to a list of previews if not already present
export const addPreviewToList = (preview: any, previews: any) => {
  if (previews.filter((prev: any) => prev.previewId === preview.previewId).length === 0) return [...previews, preview]
  return previews
}

export const getContainer = async (firestore: Firestore, canvasId: string, containerId: string) => {
  const container = await containersQuery(firestore, canvasId)
    .doc(containerId)
    .get()
    .catch(function (error: any) {
      console.warn('Error getting container:', error)
    })
  return container
}

export const addContainerInstance = async (
  firestore: Firestore,
  canvasId: string,
  containerId: string,
  X: number,
  Y: number,
  scale: number,
) => {
  const instanceId = uuidv4()
  await containersQuery(firestore, canvasId)
    .doc(containerId)
    .update({
      instances: firebase.firestore.FieldValue.arrayUnion({
        X,
        Y,
        scale,
        instanceId,
      }),
    })
    .catch((error: any) => {
      toast.error('Error adding container instance: ' + error)
      debugger
    })
}

export const getUserMemberships = (userUid: string) => {
  return firestore.collectionGroup('memberships').where('userId', '==', userUid).get()
}

export const inviteUsersToTeam = async (teamId: string, inviteEmails: string[]) => {
  const results = await firebase.functions().httpsCallable('addInvitedUsersToTeam')({ teamId, inviteEmails })

  // TODO:
  // We must send the sign in link from clientside atm,
  // b/c we are re-using send sign in email as invite email
  // ideally this is sent from cloud function, but would require
  // custom email functionality
  const invitePromises = inviteEmails.map(email => {
    return firebase.auth().sendSignInLinkToEmail(email, FIREBASE_ACTION_CODE_SETTINGS)
  })

  await Promise.all(invitePromises)

  mixpanel.track('Teammates Invite', { 'Teammate Count': inviteEmails.length })

  return results.data.memberships as IMembership[]
}

export const createProject = async (
  firestore: Firestore,
  teamId: string,
  userId: string,
  projectName: string,
  canvasName: string = NEW_CANVAS_NAME,
  copyOfCanvasId?: string,
) => {
  const projectRef = firestore.collection('teams').doc(teamId).collection('projects').doc()
  await projectRef.set({
    createdAt: Timestamp.now(),
    name: projectName,
    creatorId: userId,
  })

  mixpanel.track('Project Creation')

  const canvasRef = firestore.collection('canvases').doc()
  await canvasRef.set({
    createdAt: Timestamp.now(),
    project: projectRef,
    name: canvasName,
    copyOfCanvasId: copyOfCanvasId || '',
  })

  mixpanel.track('Canvas Creation')

  return canvasRef
}

export const updateUserName = async (user: firebase.User, name: string) => {
  // TODO: possible that auth and firestore can get out of sync
  // if the firebase auth profile update fails
  // firebase auth is helpful for basic profile info
  // it doesn't require fetching user firestore doc after login
  // might be better in future to just user firestore as single source of truth
  await firestore.collection('users').doc(user.uid).update({
    displayName: name,
  })

  await user.updateProfile({
    displayName: name,
  })
}

export const uploadImage = async (file: File) => {
  const imageRef = firebaseStorageRef.child(`images/${uuidv4()}/${file.name}`)

  const imageSnapshot = await imageRef.put(file)
  return await imageSnapshot.ref.getDownloadURL()
}
