import './H2.scss'

import React from 'react'

const H2 = ({ children, ...props }: any) => (
  <h2 className={`h2`} {...props}>
    {children}
  </h2>
)

export default H2
