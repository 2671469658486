import './H1.scss'

import React from 'react'

const H1 = ({ children, ...props }: any) => (
  <h1 className={`h1`} {...props}>
    {children}
  </h1>
)

export default H1
