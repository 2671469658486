import './Input.scss'
import { ErrorMessage } from 'formik'
import { Popover } from 'react-tiny-popover'
import { InputError } from '../../atoms'

import React from 'react'

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  large?: boolean
  fullWidth?: boolean
  validation?: boolean
  id?: string
  className?: string
}

const Input = ({ large, fullWidth, validation, id, className, ...restOfProps }: IProps) => {
  if (id && validation) {
    return (
      <Popover
        isOpen={true}
        padding={3}
        positions={['bottom']}
        align={'start'}
        content={<ErrorMessage name={id}>{msg => <InputError>{msg}</InputError>}</ErrorMessage>}
      >
        <input
          className={`input ${large ? 'input--large' : 'input--medium'} ${fullWidth ? 'input--full' : ''} ${
            className || ''
          }`}
          {...restOfProps}
        />
      </Popover>
    )
  }
  return (
    <input
      className={`input ${large ? 'input--large' : 'input--medium'} ${fullWidth ? 'input--full' : ''}`}
      {...restOfProps}
    />
  )
}

export default Input
