import './InputError.scss'

import React from 'react'

interface IProps {
  children: any
}

const InputError = ({ children }: IProps) => {
  return <div className="input-error">{children}</div>
}

export default InputError
