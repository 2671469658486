import firebase from 'firebase/app'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { toast } from 'react-toastify'
import { Settings, SidebarProjects } from '../'
import ExpandSVG from '../../../assets/icons/action-arrow-down_darkGray2.svg'
import SettingSVG from '../../../assets/icons/menu-settings_darkGray3.svg'
import { DEFAULT_TEAM_LOGO_URL } from '../../../constants/moss'
import CurrentTeamContext from '../../../contexts/CurrentTeamContext'
import { firestore, ICanvas } from '../../../firebase'
import { IProject } from '../../../types/projects'
import { ITeam } from '../../../types/teams'
import { Card, Divider, H3, Icon, ListItem, Paragraph, UnstyledButton } from '../../atoms'
import SquareLogo from '../../atoms/SquareLogo/SquareLogo'
import { Modal } from '../../molecules'
import SidebarCanvases from '../SidebarCanvases'
import './Sidebar.scss'

interface IProps {
  isSidebarOpen: boolean
  selectedProject: firebase.firestore.DocumentReference
  selectedCanvasId: string
  teams: ITeam[]
  projects: IProject[]
  canvases: ICanvas[]
}

export default function Sidebar(props: IProps) {
  const [isOpenTeamSelect, setOpenTeamSelect] = useState(false)
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  const currentTeamContext = useContext(CurrentTeamContext)
  const history = useHistory()

  const settingsButtonHandler = () => {
    setSettingsModalOpen(true)
  }

  const logoutHandler = () => {
    firebase
      .auth()
      .signOut()
      .catch(function (error) {
        // TODO: figure out proper alert
        toast.error('An error occurred while trying to logout.')
      })
  }

  const teamButtonHandler = async (teamId: string) => {
    // TODO: how to show loading and error states for this
    try {
      // Redirect to team's first canvas in first project
      const querySnapshot = await firestore.collection('teams').doc(teamId).collection('projects').get()

      if (querySnapshot.size === 0) {
        console.log('Team has no projects')
        return
      }

      const canvasQuerySnapshot = await firestore
        .collection('canvases')
        .where('project', '==', querySnapshot.docs[0].ref)
        .get()

      if (canvasQuerySnapshot.size === 0) {
        console.log('No canvas exists for the team')
        return
      }

      history.push(`/canvas/${canvasQuerySnapshot.docs[0].id}`)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={`sidebar ${props.isSidebarOpen ? 'sidebar--open' : ''}`}>
      <Popover
        isOpen={isOpenTeamSelect}
        onClickOutside={() => setOpenTeamSelect(false)}
        positions={['bottom']}
        align={'start'}
        padding={-5}
        boundaryInset={12}
        content={
          <Card className="team-select--popover">
            <Card.Body padding="list" size="medium">
              {props.teams.map(team => (
                <UnstyledButton key={team.id} onClick={() => teamButtonHandler(team.id)} fullWidth={true}>
                  <ListItem size="large">
                    <SquareLogo size="large" logoAlt="Team logo" logoSrc={team.logoUrl || DEFAULT_TEAM_LOGO_URL} />
                    <span>
                      <H3>{team.name}</H3>
                      <Paragraph className="description">
                        {team.membershipCount} {team.membershipCount === 1 ? 'Member' : 'Members'}
                      </Paragraph>
                    </span>
                  </ListItem>
                </UnstyledButton>
              ))}

              <Divider />

              <UnstyledButton onClick={logoutHandler} fullWidth={true}>
                <ListItem size="small">
                  <Paragraph className="log-out">Log out of all teams</Paragraph>
                </ListItem>
              </UnstyledButton>
            </Card.Body>
          </Card>
        }
      >
        <div className="team-select--wrapper">
          <UnstyledButton
            className={`team-select ${isOpenTeamSelect ? 'company_selected' : ''}`}
            onClick={() => setOpenTeamSelect(oldTeamSelectOpen => !oldTeamSelectOpen)}
            tooltip={isOpenTeamSelect ? '' : 'Switch teams, logout'}
          >
            <ListItem size="team-select">
              <SquareLogo
                logoAlt="Team logo"
                logoSrc={currentTeamContext.currentTeam?.logoUrl || DEFAULT_TEAM_LOGO_URL}
              />

              <H3>{currentTeamContext.currentTeam ? currentTeamContext.currentTeam.name : 'Team not found'}</H3>

              <Icon src={ExpandSVG} alt="Expand icon" className="expandMore" />
            </ListItem>
          </UnstyledButton>
        </div>
      </Popover>

      <SidebarProjects selectedProjectId={props.selectedProject.id} projects={props.projects} />

      <SidebarCanvases
        canvases={props.canvases}
        selectedCanvasId={props.selectedCanvasId}
        selectedProject={props.selectedProject}
      />

      <div className="settings-button--wrapper">
        <UnstyledButton className="settings-button" onClick={settingsButtonHandler} tooltip="Settings">
          <ListItem tag="div" size="settings">
            <Icon alt="Settings icon" src={SettingSVG} />
            <Paragraph>Settings</Paragraph>
          </ListItem>
        </UnstyledButton>
      </div>

      <Modal isOpen={settingsModalOpen} onRequestClose={() => setSettingsModalOpen(false)}>
        <Settings onCancelClick={() => setSettingsModalOpen(false)} />
      </Modal>
    </div>
  )
}
