import './TopMenu.scss'

import React from 'react'

import CloseSidebarSVG from '../../../assets/icons/action-arrow-double-left_darkGray2.svg'
import OpenSidebarSVG from '../../../assets/icons/menu-list_darkGray2.svg'
import { Button, Icon } from '../../atoms'

interface Props {
  isSidebarOpen: boolean
  setOpenSidebar: (open: boolean) => void
  selectedProjectName: string
  selectedCanvasName: string
}

export default function TopMenu(props: Props) {
  return (
    <div className="topMenu">
      <Button
        size="small"
        variant="white"
        borderless={true}
        onClick={() => props.setOpenSidebar(!props.isSidebarOpen)}
        tooltip={props.isSidebarOpen ? '' : 'Open the sidebar'}
        tooltipPos={'bottom'}
        tooltipAlign={'start'}
      >
        <Icon
          src={!props.isSidebarOpen ? OpenSidebarSVG : CloseSidebarSVG}
          alt={!props.isSidebarOpen ? 'Open Sidebar Icon' : 'Close Sidebar Icon'}
        />
      </Button>

      {/* Currently, no button functionality until Recents is added */}
      <Button
        color="white"
        size="small"
        borderless={true}
        className="breadcrumb"
        tooltip="Your current project & canvas"
        tooltipPos={'bottom'}
        tooltipAlign={'start'}
      >
        {props.selectedProjectName} / {props.selectedCanvasName}
      </Button>
    </div>
  )
}
